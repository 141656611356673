import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/shared/CheckBox';

export default function CheckBoxGroup({ onCheckboxChange }) {
  const { t } = useTranslation();
  //set order type
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  function handleCheckbox1Change() {
    setIsChecked1(!isChecked1);
    setIsChecked2(false);
    setIsChecked3(false);
    onCheckboxChange({
      checkbox1: !isChecked1,
      checkbox2: false,
      checkbox3: false,
    });
  }

  function handleCheckbox2Change() {
    setIsChecked2(!isChecked2);
    setIsChecked1(false);
    setIsChecked3(false);
    onCheckboxChange({
      checkbox1: false,
      checkbox2: !isChecked2,
      checkbox3: false,
    });
  }

  function handleCheckbox3Change() {
    setIsChecked3(!isChecked3);
    setIsChecked1(false);
    setIsChecked2(false);
    onCheckboxChange({
      checkbox1: false,
      checkbox2: false,
      checkbox3: !isChecked3,
    });
  }

  return (
    <div className="col-12 col-md-9 d-flex justify-content-between justify-content-md-between align-items-center flex-column flex-md-row">
      <div className="col-12 col-md-3 d-flex align-items-center">
        <div className="label-title me-3"> {t('Rédaction premium')}*</div>
        <CheckBox
          name="Checkbox 1"
          tick={isChecked1}
          onCheck={handleCheckbox1Change}
        />
      </div>
      <div className="col-12 col-md-3 d-flex align-items-center">
        <div className="label-title me-3"> {t('Rédaction avec révision')}*</div>

        <CheckBox
          name="Checkbox 2"
          tick={isChecked2}
          onCheck={handleCheckbox2Change}
        />
      </div>
      <div className="col-12 col-md-3 d-flex align-items-center">
        <div className=" label-title me-3"> {t('Rédaction intelligente')}*</div>

        <CheckBox
          name="Checkbox 3"
          tick={isChecked3}
          onCheck={handleCheckbox3Change}
        />
      </div>
    </div>
  );
}
