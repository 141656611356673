import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@mui/material/Switch';
import { Tooltip } from '@mui/material';

import { adminOrderDisplaySelector } from 'store/Admin/OrderDisplay';
import { clientOrderDisplaySelector } from 'store/Client/OrderDisplay';
import { UserSessionSelector } from 'store/UserSession';
import { fetchText } from 'store/Admin/Analyser/features';
import {
  setOrderId as setAdminOrderId,
  setTextType as adminSetTextType,
} from 'store/Admin/Analyser';

import {
  setOrderId,
  setTextType as writerSetTextType,
} from 'store/Writer/Analyser';

import {
  setClientOrderId,
  setTextType as clientSetTextType,
  setWriterCommandId,
} from 'store/Client/ClientAnalyser';

import {
  setConsultantOrderId,
  setTextType as consultantSetTextType,
} from 'store/Consultant/ConsultantAnalyser';
import { fetchConsultantText } from 'store/Consultant/ConsultantAnalyser/features';
import { fetchClientText } from 'store/Client/ClientAnalyser/features';
import { fetchWriterText } from 'store/Writer/Analyser/features';

import { writerOrderDisplaySelector } from 'store/Writer/OrderDisplay';

import { formatDate } from 'helpers/Constants';
import { getTextColor } from 'helpers/TextColorHelper';
import { textFilterByStatus } from 'helpers/FilterOrders';
import { handleSortingChange } from 'helpers/SortingHelper';

import {
  ROLES,
  pageNbOptions,
  filterTextByStatus,
  filterTextByType,
} from 'utils/Constants';
import { useHistoryState } from 'utils/hooks/useHistoryState';
import { useTextFilter } from 'utils/hooks/useTextFilter';
import { lower } from 'utils/StringUtils';
import {
  shouldShowRevisionCheckBox,
  shouldShowValidationCheckBox,
  handleRevisionCheck,
  handleValidationCheck,
} from 'helpers/CheckBoxHelper';

import { GlobalTextsValidation } from 'helpers/GlobalTextValidationHelper';

import { Paginator } from 'components/shared/Paginator';
import { Loader } from 'components/shared/Loader';

import ErrorComponent from 'components/shared/ErrorComponent';
import CustomFilterMenu from 'components/shared/Filter/CustomFilterMenu';
import CustomSearchBox from 'components/shared/SearchBar/CustomSearchBox';
import { CustomTooltip } from 'components/shared/CustomTooltip';
import CheckBox from 'components/shared/CheckBox';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import { ExcelExportButton } from 'components/shared/ExcelExport/ExcelExportButton';

import TextCheckboxTableRow from './TextCheckboxTableRow';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { URLInputModal } from 'components/shared/modal/URLInputModal';
import {
  DownloadOneTextWordType,
  DownloadTextsListWordType,
} from 'components/shared/DownloadFile/DownloadTextWordType';
import DownloadModal from 'components/shared/modal/DownloadModal';

import CardTextIcon from 'icons/CardTextIcon';
import TranslateIcon from 'icons/TranslateIcon';
import LinkIcon from 'icons/LinkIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';
import { animationUrl } from 'config/endpoints';
import { CLIENT_HISTORY_TABLE, HISTORY_TABLE } from 'utils/Constants';

import GlobalRevisionRequestModal from './modal/GlobalRevisionRequestModal';
import axiosPrivate from 'config/axiosPrivate';
import RevisionDocumentModal from './modal/RevisionDocumentModal';

export default function OrderHistory(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector(UserSessionSelector);

  const orderDisplaySelector =
    user && user.role === ROLES.CLIENT
      ? clientOrderDisplaySelector
      : user && user.role === ROLES.WRITER
      ? writerOrderDisplaySelector
      : adminOrderDisplaySelector;

  const { order, textList, isTextLoading, isTextError } =
    useSelector(orderDisplaySelector);

  const columns =
    user && user.role === ROLES.CLIENT ? CLIENT_HISTORY_TABLE : HISTORY_TABLE;

  const [currentTexts, setCurrentTexts] = useState([]);
  const [filteredTexts, setFilteredTexts] = useState([]);
  const [textTitle, setTextTitle] = useState('');
  const [textId, setTextId] = useState(null);
  const [revisionFileName, setRevisionFileName] = useState(null);
  const [revisionText, setRevisionText] = useState('');
  const [revisionRequestDate, setRevisionRequestDate] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);

  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedStatus, setSelectedStatus] = useState(filterTextByStatus[0]);
  const [selectedType, setSelectedType] = useState(filterTextByType[0]);
  const [selectedTextsForRevision, setSelectedTextsForRevision] = useState([]);
  const [deploymentQueue, setDeploymentQueue] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sortedText, setOrdredText] = useState([]);
  const [isTranslate, setIsTranslate] = useState(false);
  const [isText, setIsText] = useState(false);
  const [isCombo, setIsCombo] = useState(false);
  const [searchValue, setSearchValue] = useHistoryState('searchValue', '');

  const [elementsPerPage, setElementsPerPage] = useState(10);
  const [showRevisionModal, setShowRevisionModal] = useState(false);
  const [showUrlModal, setShowUrlModal] = useState(false);
  const [requestText, setRequestText] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isSomeTextSelected, setIsSomeTextSelected] = useState(false);
  const [isAllTextsSelected, setIsAllTextsSelected] = useState(false);
  const [isGlobalDeployementDisabled, setIsGlobalDeployementDisabled] =
    useState(false);

  const [selectedTextsForValidation, setSelectedTextsForValidation] = useState(
    []
  );

  const [openRevisionDocument, setOpenRevisionDocument] = useState(false);

  const [errorSuccessImg, setErrorSuccessImg] = useState(undefined);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [showDownloadModal, setShowDownloadModel] = useState(false);

  const {
    isTextEligibleForValidation,
    availbleRevisionNumber,
    availableValidationNumber,
    isTextEligibleForRevision,
    isTranslateOnly,
    isTextOnly,
    isCombined,
  } = useTextFilter(sortedText);

  const open = Boolean(anchorEl);

  const fieldWidths = {
    textTitle: '30%',
    createdAt: '10%',
  };

  const filterTextByStatusArr = filterTextByStatus;

  // Determine texts awaiting deployment
  const awaitingDeploymentAfterValidation = (text) =>
    text.validated && !text.deployed;

  const filterTextsForDeployment = (texts) =>
    texts.filter(awaitingDeploymentAfterValidation).map((filteredText) => ({
      id: filteredText.id,
      title: filteredText.keyword,
      url: filteredText.url,
      deployed: filteredText.deployed,
    }));

  const awaitingDeploymentTexts = filterTextsForDeployment(textList);

  // Effect to handle text selection logic
  useEffect(() => {
    setIsSomeTextSelected(
      deploymentQueue.length > 1 &&
        deploymentQueue.length < awaitingDeploymentTexts.length
    );
    setIsAllTextsSelected(
      deploymentQueue.length > 1 &&
        deploymentQueue.length === awaitingDeploymentTexts.length
    );
  }, [deploymentQueue, awaitingDeploymentTexts.length]);

  useEffect(() => {
    setIsGlobalDeployementDisabled(
      currentTexts.length === 0 || awaitingDeploymentTexts.length === 0
    );
  }, [currentTexts, awaitingDeploymentTexts.length]);

  useEffect(() => {
    if (sortField) {
      setOrdredText(filteredTexts);
      return;
    }
    setOrdredText([
      ...filteredTexts.filter((text) => text['validated'] === false),
      ...filteredTexts.filter((text) => text['validated'] === true),
    ]);
  }, [filteredTexts]);

  useEffect(() => {
    const filtered = textFilterByStatus(textList, selectedStatus);
    setCurrentPage(1);
    setFilteredTexts(filtered);
  }, [selectedStatus, textList]);

  useEffect(() => {
    if (selectedType.field.length > 0) {
      setCurrentPage(1);
      setFilteredTexts(
        textList.filter(
          (text) =>
            (isCombo && text.type === 'COMBINED') ||
            (isTranslate && text.type === 'TRANSLATED') ||
            (isText && text.type === 'NON_TRANSLATED') ||
            (!isCombo && !isTranslate && !isText)
        )
      );
    } else {
      setFilteredTexts([...textList]);
    }
  }, [selectedType, textList]);

  useEffect(() => {
    if (searchValue && searchValue.length > 0) {
      setCurrentPage(1);
      setFilteredTexts(
        textList.filter((x) => {
          return lower(x.textTitle).includes(lower(searchValue));
        })
      );
    } else {
      setFilteredTexts([...textList]);
    }
  }, [searchValue, textList]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * elementsPerPage;
    const indexOfFirstOrder = indexOfLastOrder - elementsPerPage;
    setCurrentTexts(sortedText.slice(indexOfFirstOrder, indexOfLastOrder));
    setPageNumbers(
      [...Array(Math.ceil(sortedText.length / elementsPerPage)).keys()].map(
        (i) => i + 1
      )
    );
  }, [currentPage, sortedText, elementsPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  const handleStringSorting = (sortField, sortOrder) => {
    return [...filteredTexts].sort((a, b) => {
      if (a[sortField] === null) return 1;
      if (b[sortField] === null) return -1;
      return (
        a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
          numeric: true,
        }) * (sortOrder === 'asc' ? 1 : -1)
      );
    });
  };

  const handleBooleanSorting = (sortField, sortOrder) => {
    return [...filteredTexts].sort((a, b) => {
      // A special case needs to be made for the Revision Sent field
      // as a text's revision status is tracked by both revisionSent and globalRevisionSent
      // properties. In this case, we take the union of both fields for the purposes of comparison
      const effectiveValueForA =
        sortField == 'revisionSent'
          ? a['revisionSent'] || a['globalRevisionSent']
          : a[sortField];
      const effectiveValueForB =
        sortField == 'revisionSent'
          ? b['revisionSent'] || b['globalRevisionSent']
          : b[sortField];

      if (effectiveValueForA === null) return 1;
      if (effectiveValueForB === null) return -1;

      const sortOrderModifier = sortOrder === 'asc' ? 1 : -1;
      if (effectiveValueForA == effectiveValueForB) {
        // a and b are equal
        return 0;
      }

      // if a is true, it means b is false
      // so a is bigger than b
      if (effectiveValueForA) {
        return 1 * sortOrderModifier;
      }

      // we can only reach this point if a is not equal to b
      // and a is not true => a is false and b is true
      return -1 * sortOrderModifier;
    });
  };

  const handleSorting = (sortField, sortOrder) => {
    if (!sortField) {
      return;
    }
    if (!filteredTexts) {
      return;
    }

    const sorted = filteredTexts
      .map((text) => text[sortField])
      .some((e) => typeof e === 'boolean')
      ? handleBooleanSorting(sortField, sortOrder)
      : handleStringSorting(sortField, sortOrder);

    setFilteredTexts(sorted);
  };

  const redirect = (
    user,
    role,
    orderId,
    textId,
    writerCommandId,
    texteType
  ) => {
    if (!user) return;
    switch (role) {
      case ROLES.WRITER:
        dispatch(setOrderId(orderId));
        dispatch(fetchWriterText(textId));
        dispatch(writerSetTextType(texteType));
        history.push('/Redacteur/TextDetails');
        break;
      case ROLES.CLIENT:
        dispatch(setClientOrderId(orderId));
        dispatch(fetchClientText(textId));
        dispatch(setWriterCommandId(writerCommandId));
        dispatch(clientSetTextType(texteType));
        history.push('/Client/TextDetails');
        break;
      case ROLES.ADMIN:
        dispatch(setAdminOrderId(orderId));
        dispatch(fetchText(textId));
        dispatch(adminSetTextType(texteType));
        history.push('/Admin/TextDetails');
        break;

      case ROLES.CONSULTANT:
        dispatch(setConsultantOrderId(orderId));
        dispatch(fetchConsultantText(textId));
        dispatch(consultantSetTextType(texteType));
        history.push('/Consultant/TextDetails');
        break;

      default:
        break;
    }
  };

  const handleDeploy = async (deployObjects) => {
    try {
      await axiosPrivate.post('/texts/deploy', deployObjects);
    } catch (error) {
      console.error('Error updating backend:', error);
    }
  };

  const handleSwitchChange = async (textId, isDeployed, textUrl, textTitle) => {
    if (isDeployed) {
      try {
        const updatedTexts = currentTexts.map((text) =>
          text.id === textId ? { ...text, deployed: !text.deployed } : text
        );
        setCurrentTexts(updatedTexts);

        const deployObjects = [
          { textId: textId, url: textUrl, deploy: !isDeployed },
        ];

        await handleDeploy(deployObjects);
      } catch (error) {
        console.error('Error updating backend:', error);
      }
    } else {
      setDeploymentQueue([
        { id: textId, title: textTitle, url: textUrl, deploy: !isDeployed },
      ]);

      setShowUrlModal(true);
    }
  };

  const updateDeploymentQueue = (updatedQueue) => {
    if (updatedQueue.length <= 0) setIsChecked(false);
    setDeploymentQueue(updatedQueue);
  };

  const deploySelectedTexts = async () => {
    setShowUrlModal(true);
  };

  const deployAllTexts = () => {
    const filteredTexts = filterTextsForDeployment(textList);

    setDeploymentQueue(filteredTexts);
    setShowUrlModal(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGlobalSwitchChange = () => {
    setIsChecked((prev) => !prev);
    if (!isChecked) {
      if (!isSomeTextSelected) {
        // Deploy all texts if none initially selected
        setDeploymentQueue(awaitingDeploymentTexts);

        deployAllTexts();
      } else {
        // Deploy selected texts
        deploySelectedTexts();
      }
    } else {
      // Switch turned off: Clear the deployment queue
      setDeploymentQueue([]);
    }
  };

  const handleOpenRevisionDocument = async (
    id,
    title,
    name,
    revisionText,
    date
  ) => {
    setOpenRevisionDocument(true);
    setTextId(id);
    setTextTitle(title);
    setRevisionFileName(name);
    setRevisionText(revisionText);
    setRevisionRequestDate(formatDate(date));
  };

  const handleCloseRevisionModal = () => {
    setOpenRevisionDocument(false);
  };

  const handleValidation = async () => {
    await GlobalTextsValidation(
      selectedTextsForValidation,
      order.id,
      props.orderTextAmount,
      null,
      user?.role,
      setConfirmation,
      setIsSuccess,
      setErrorSuccessMsg,
      setErrorSuccess,
      t,
      dispatch
    );
  };

  if (isTextLoading) return <Loader />;

  if (isTextError) return <ErrorComponent />;

  return (
    <div className="row ">
      {' '}
      <div className="row justify-content-end align-items-center">
        <div className="col-2 d-flex align-items-center">
          <CustomFilterMenu
            selectedStatus={selectedStatus}
            handleClick={handleClick}
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            isTranslateOnly={isTranslateOnly}
            isTextOnly={isTextOnly}
            isCombined={isCombined}
            t={t}
            isText={isText}
            isTranslate={isTranslate}
            isCombo={isCombo}
            setIsTranslate={setIsTranslate}
            setIsText={setIsText}
            setIsCombo={setIsCombo}
            setSelectedStatus={setSelectedStatus}
            filterTextByStatusArr={filterTextByStatusArr}
            filterTextByType={filterTextByType}
            setSelectedType={setSelectedType}
            flag={true}
          />
          {textList.length > 0 && (
            <div className="ms-4">
              <ExcelExportButton
                orderTitle={order.title}
                orderId={order.id}
                orderLanguage={order.language}
              />
            </div>
          )}
        </div>
        <div className="col-10 align-items-center">
          <CustomSearchBox
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            elementsPerPage={elementsPerPage}
            setElementsPerPage={setElementsPerPage}
            pageNbOptions={pageNbOptions}
            t={t}
          />{' '}
        </div>
      </div>
      <div className="col-12 col-md-12">
        <div className="box-tabs-content">
          <div className="box-wrapper" style={{ marginTop: 15 }}>
            <div className="table-responsive">
              <div>
                <table className="table-lists table-commandes table-history">
                  <thead>
                    <tr>
                      {columns.map(({ label, field, sortable }) => (
                        <th
                          key={field}
                          style={{
                            cursor: sortable ? 'pointer' : 'default',
                            width: fieldWidths[field] || '',
                          }}
                        >
                          {sortable ? (
                            <span
                              style={{ cursor: 'pointer', marginRight: '10px' }}
                              onClick={() =>
                                handleSortingChange(
                                  field,
                                  sortField,
                                  sortOrder,
                                  setSortField,
                                  setSortOrder,
                                  handleSorting
                                )
                              }
                            >
                              {' '}
                              {t(label)}
                              {field === sortField && sortOrder === 'asc' ? (
                                <span>
                                  <ChevronUpIcon
                                    style={{ marginLeft: '3px' }}
                                  />
                                </span>
                              ) : (
                                <span>
                                  <ChevronDownIcon
                                    style={{ marginLeft: '3px' }}
                                  />
                                </span>
                              )}
                            </span>
                          ) : (
                            <span>{t(label)}</span>
                          )}
                          {field === 'textTitle' ? (
                            <CustomTooltip
                              title={
                                <span>
                                  <p>
                                    {t('Noir')} :{' '}
                                    {t(
                                      'contenu en attente de vérification client'
                                    )}
                                  </p>
                                  <p>
                                    {t('Orange')} :{' '}
                                    {t(
                                      'contenu en attente de repasse rédacteur'
                                    )}
                                  </p>
                                  <p>
                                    {t('Violet')} :{' '}
                                    {t(
                                      'contenu repassé, en attente validation client'
                                    )}
                                  </p>
                                  <p>
                                    {t('Vert')} :{' '}
                                    {t('contenu validé par le client')}
                                  </p>
                                </span>
                              }
                              placement="top"
                            >
                              <button
                                type="button"
                                className="btn-circle"
                              ></button>
                            </CustomTooltip>
                          ) : (
                            ''
                          )}
                          {field === 'selectAll' &&
                            shouldShowRevisionCheckBox(
                              order?.type,
                              user?.role,
                              availbleRevisionNumber,
                              order.revisionDisabled
                            ) && (
                              <CheckBox
                                name="select-all"
                                tick={selectedTextsForRevision.length > 1}
                                onCheck={(e) =>
                                  handleRevisionCheck(
                                    e,
                                    sortedText,
                                    setSelectedTextsForRevision
                                  )
                                }
                              />
                            )}
                          {field === 'selectAll' &&
                            shouldShowValidationCheckBox(
                              user?.role,
                              availableValidationNumber,
                              order?.type
                            ) && (
                              <CheckBox
                                name="select-all"
                                tick={
                                  selectedTextsForValidation.length ===
                                  availableValidationNumber
                                }
                                onCheck={(e) =>
                                  handleValidationCheck(
                                    e,
                                    sortedText,
                                    setSelectedTextsForValidation,
                                    isTextEligibleForValidation
                                  )
                                }
                              />
                            )}
                          {field === 'downloadAll' &&
                          user &&
                          user.role === ROLES.CLIENT ? (
                            sortedText.filter((text) => text.delivered)
                              .length === 0 ? (
                              <span className=" bt-download-circle float-end disabled"></span>
                            ) : (
                              <span
                                className="  bt-download-circle float-end"
                                onClick={() =>
                                  DownloadTextsListWordType(
                                    sortedText.filter((text) => text.delivered),
                                    user,
                                    setShowDownloadModel
                                  )
                                }
                              ></span>
                            )
                          ) : (
                            ''
                          )}
                          {user.role === ROLES.CLIENT &&
                            field === 'deployAll' && (
                              <Tooltip title={t('Contenu en ligne')}>
                                <label
                                  className={`switch ${
                                    (isSomeTextSelected ||
                                      isAllTextsSelected) &&
                                    !isChecked
                                      ? 'inactive'
                                      : ''
                                  }`}
                                >
                                  <input
                                    disabled={isGlobalDeployementDisabled}
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleGlobalSwitchChange}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </Tooltip>
                            )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentTexts.map((text, idx) => (
                      <tr key={idx}>
                        <td className="d-flex flex-row">
                          <div
                            className="d-flex flex-row w-100 ps-1 align-items-center"
                            onClick={() => {
                              if (
                                user &&
                                user.role === ROLES.CLIENT &&
                                !text.delivered
                              ) {
                                setErrorSuccessMsg(
                                  t(`Texte en cours de rédaction !`)
                                );
                                setErrorSuccessImg(
                                  animationUrl +
                                    '/03_Commande_en_cours_de_rédaction.gif'
                                );
                                setIsSuccess(true);
                                setIsInfo(true);
                                setErrorSuccess(true);
                              } else {
                                redirect(
                                  user,
                                  user.role,
                                  order.id,
                                  text.id,
                                  text.writerCommandId,
                                  text.type
                                );
                              }
                            }}
                          >
                            <span style={{ cursor: 'pointer' }}>
                              <span className={`${getTextColor(text)}`}>
                                {(text.type === 'NON_TRANSLATED' ||
                                  text.type === 'COMBINED') && <CardTextIcon />}
                                {text.type === 'COMBINED' ? ' + ' : ''}
                                {(text.type === 'TRANSLATED' ||
                                  text.type === 'COMBINED') && (
                                  <TranslateIcon />
                                )}
                                {'  '}
                                {text.keyword}
                              </span>
                            </span>
                          </div>
                          {text.url && text.url.length > 0 && (
                            <span
                              className=" d-flex flex-row ps-1 align-items-center bt-circle-wh"
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => window.open(text.url, '_blank')}
                            >
                              <LinkIcon />
                            </span>
                          )}
                        </td>
                        <td>
                          <span>{formatDate(text.createdAt)}</span>
                        </td>
                        <td>
                          <span>
                            {text.edited ? `${t('Oui')}` : `${t('Non')}`}
                          </span>
                        </td>
                        <td>
                          {text.revisionSent || text.globalRevisionSent ? (
                            <div className="d-flex align-items-center justify-content-center ">
                              <span>{t('Oui')}</span>{' '}
                              <div
                                className="bt-icon-msg"
                                onClick={() =>
                                  handleOpenRevisionDocument(
                                    text.id,
                                    text.textTitle,
                                    text.revisionFileName,
                                    text.revisionText,
                                    text.revisionRequestAt
                                  )
                                }
                              >
                                <img
                                  src="/Images/icon-message.png"
                                  alt="icon-message"
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center justify-content-center ">
                              <span>{t('Non')}</span>
                              <div style={{ width: '36px' }}></div>
                            </div>
                          )}
                        </td>
                        <td>
                          <span className={text.validated ? 'text-green' : ''}>
                            {text.validated ? `${t('Oui')}` : `${t('Non')}`}
                          </span>
                        </td>
                        <td>
                          <span className={text.validated ? 'text-green' : ''}>
                            {text.validateAt
                              ? formatDate(text.validateAt)
                              : '-'}
                          </span>
                        </td>
                        <td>
                          {user?.role === ROLES.CLIENT && !text.delivered ? (
                            <span className="bt-download-circle-disabled float-end" />
                          ) : (
                            <span
                              className="bt-download-circle float-end"
                              onClick={() =>
                                DownloadOneTextWordType(text.id, user)
                              }
                            />
                          )}
                        </td>

                        {user?.role !== ROLES.CLIENT && (
                          <td>
                            {text.deployed && (
                              <Tooltip title={t('Contenu en ligne')}>
                                <span>
                                  <img
                                    src="/Images/check-mark.png"
                                    alt="check icon"
                                    style={{ height: '20px' }}
                                  />
                                </span>
                              </Tooltip>
                            )}
                          </td>
                        )}

                        {user && user.role === ROLES.CLIENT && (
                          <td>
                            {text.validated && (
                              <Tooltip title={t('Contenu en ligne')}>
                                <Switch
                                  color="green"
                                  checked={text.deployed}
                                  onChange={(e) =>
                                    handleSwitchChange(
                                      text.id,
                                      text.deployed,
                                      text.url,
                                      text.keyword
                                    )
                                  }
                                />
                              </Tooltip>
                            )}
                          </td>
                        )}

                        {user?.role === ROLES.CLIENT && (
                          <TextCheckboxTableRow
                            text={text}
                            order={order}
                            selectedTextsForRevision={selectedTextsForRevision}
                            deploymentQueue={deploymentQueue}
                            setDeploymentQueue={setDeploymentQueue}
                            selectedTextsForValidation={
                              selectedTextsForValidation
                            }
                            setSelectedTextsForRevision={
                              setSelectedTextsForRevision
                            }
                            setSelectedTextsForValidation={
                              setSelectedTextsForValidation
                            }
                            isEligibleForRevision={isTextEligibleForRevision(
                              text,
                              order.revisionDisabled,
                              order.type
                            )}
                            isAwaitingDeployment={awaitingDeploymentAfterValidation(
                              text
                            )}
                            isEligibleForValidation={isTextEligibleForValidation(
                              text
                            )}
                          />
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {user?.role === ROLES.CLIENT &&
                order.type !== 'SMART' &&
                availbleRevisionNumber > 1 &&
                !order.revisionDisabled && (
                  <button
                    className={
                      selectedTextsForRevision.length < 2
                        ? 'bt-submit-disabled-y'
                        : 'bt-submit-y'
                    }
                    type="submit"
                    onClick={() => setShowRevisionModal(true)}
                    disabled={selectedTextsForRevision.length < 2}
                  >
                    {t('Demander une repasse globale')}
                  </button>
                )}

              {user.role === ROLES.CLIENT &&
                availableValidationNumber > 0 &&
                order.type === 'SMART' && (
                  <button
                    className={
                      selectedTextsForValidation.length < 1 ||
                      availableValidationNumber < 1
                        ? 'bt-submit-disabled-y'
                        : 'bt-submit-y'
                    }
                    type="submit"
                    onClick={() => {
                      setConfirmationMsg(
                        `${t(
                          'Voulez-vous vraiment valider les Textes de la commande'
                        )} ${order.title} ? `
                      );
                      setConfirmation(true);
                    }}
                    disabled={
                      selectedTextsForValidation.length < 1 ||
                      availableValidationNumber < 1
                    }
                  >
                    {t('Valider les textes')}
                  </button>
                )}
            </div>
          </div>

          <Paginator
            isLoading={isTextLoading}
            listLength={filteredTexts?.length}
            currentPage={currentPage}
            pageNumbers={pageNumbers}
            nbElementsPerPage={elementsPerPage}
            goToPage={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
      <GlobalRevisionRequestModal
        textIds={selectedTextsForRevision}
        showRevisionModal={showRevisionModal}
        requestText={requestText}
        selectedFile={selectedFile}
        handleClose={() => {
          setShowRevisionModal(false);
          setRequestText(null);
          setSelectedFile(null);
        }}
        setRequestText={(value) => setRequestText(value)}
        setSelectedFile={(value) => setSelectedFile(value)}
        orderId={order.id}
      />
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        imageUrl={errorSuccessImg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        info={isInfo}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
      <URLInputModal
        currentTexts={currentTexts}
        setCurrentTexts={setCurrentTexts}
        open={showUrlModal}
        handleClose={() => setShowUrlModal(false)}
        initialDeploymentQueue={deploymentQueue}
        updateDeploymentQueue={updateDeploymentQueue}
      />
      <RevisionDocumentModal
        open={openRevisionDocument}
        handleClose={handleCloseRevisionModal}
        textId={textId}
        fileName={revisionFileName}
        textTitle={textTitle}
        revisionText={revisionText}
        date={revisionRequestDate}
        toggle={() => setErrorSuccess(!openRevisionDocument)}
      />
      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={() => handleValidation()}
      />
      <DownloadModal
        open={showDownloadModal}
        handleClose={() => setShowDownloadModel(false)}
      />
    </div>
  );
}
