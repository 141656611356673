import { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { UserSessionSelector } from 'store/UserSession';

import {
  clientAnalyzerSelector,
  updateTextForm,
  updateForm,
} from 'store/Client/ClientAnalyser';
import { updateTextOfTextList } from 'store/Client/OrderDisplay';

import { fetchClientNotification } from 'store/Client/ClientNotification/features';
import {
  fetchClientOrderDisplay,
  fetchClientTextOrderDisplay,
} from 'store/Client/OrderDisplay/features';

import AnalyseText from 'components/Admin/AnalyseText';
import { DownloadOneTextWordType } from 'components/shared/DownloadFile/DownloadTextWordType';

import { setKeyWordList } from 'helpers/PdfFunction';
import { autoSaveText } from 'helpers/CreateAutoSave';
import { capitalize } from 'helpers/Capitalize';

import { ANALYSELANGAUGESLIST, SavingState } from 'utils/Constants';

import { CustomEditor } from 'components/shared/CustomEditor';
import { CustomHighcharts } from 'components/shared/CustomHighcharts';
import { CustomTooltip } from 'components/shared/CustomTooltip';
import { Loader } from 'components/shared/Loader';
import AutoSaveDisplay from 'components/shared/AutoSaveDisplay';

import ComparisonModal from 'components/shared/modal/ComparisonModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import RevisionRequestModal from 'components/shared/modal/RevisionRequestModal';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import { ClipboardCopy } from 'components/shared/ClipboardCopy';
import { RevisionDetails } from 'components/shared/RevisionDetails';

import axiosPrivate from 'config/axiosPrivate';
import axiosEvaluation from 'config/axiosEvaluation';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';
import { animationUrl } from 'config/endpoints';

function ClientTextDisplay() {
  const {
    textForm,
    orderId,
    orderType,
    isLoading,
    isError,
    writerCommandId,
    textType,
  } = useSelector(clientAnalyzerSelector);

  const { user } = useSelector(UserSessionSelector);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);

  const [textTitle, setTextTitle] = useState('');
  const [keyword, setkeyword] = useState('');
  const [url, setUrl] = useState('');
  const [titleTag, setTitleTag] = useState('');
  const [metaDescriptionTag, setMetaDescriptionTag] = useState('');

  const [actualTextContent, setActualTextContent] = useState('');
  const [actualHtmlContent, setActualHtmlContent] = useState('');
  const [analyseText, setAnalyseText] = useState('');

  // const [actualTextWordCount, setActualTextWordCount] = useState(0);

  const [analyseResult, setAnalyseResult] = useState('');
  const [list, setList] = useState({});

  const [isSave, setIsSave] = useState(false);

  const [keywords, setKeywords] = useState('');
  const [language, setLanguage] = useState(ANALYSELANGAUGESLIST[0]);
  const [loading, setLoading] = useState(false);

  const [requestText, setRequestText] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isSendRevisionDisabled, setIsSendRevisionDisabled] = useState(false);

  const [showComparison, setShowComparison] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [showRevisionModal, setShowRevisionModal] = useState(false);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [errorSuccessImg, setErrorSuccessImg] = useState(undefined);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isAllValidated, setIsAllValidated] = useState(false);
  const [isPartValidated, setIsPartValidated] = useState(false);
  const [userName, setUserName] = useState('mimi');
  const [nbParts, setNbParts] = useState(0);
  const [nbValidatedParts, setNbValidatedParts] = useState(0);
  const [saving, setSaving] = useState(SavingState.NOT_SAVED);
  const [timer, setTimer] = useState(null);

  const isRevisionSent =
    textForm &&
    ((textForm.globalRevisionSent === true &&
      textForm.revisionDone === false) ||
      (textForm.revisionSent === true &&
        textForm.revisionDone === false &&
        textForm.validated === false));

  const checkAllValidated = useCallback(async () => {
    try {
      const res = await axiosEvaluation.get(
        `/client/evaluations/allValidatedTexts?command=${orderId}`
      );
      const resPart = await axiosEvaluation.get(
        `/client/evaluations/partValidatedTexts?writer command=${writerCommandId}`
      );
      setIsAllValidated(res.data.validated);

      if (res.data.validated === true) {
        setErrorSuccessMsg(
          `${t('Le dernier texte a été validé avec succès')}!`
        );
        setErrorSuccessImg(animationUrl + '/07_Commande_validée.gif');
      }
      setIsPartValidated(resPart.data.validated);
      if (resPart.data.userName != null) {
        setUserName(resPart.data.userName);
      }
      setNbParts(res.data.numberOfParts);
      setNbValidatedParts(res.data.numberOfValidatedParts);
      if (resPart.data.validated === true && res.data.validated === false) {
        setErrorSuccessMsg(`${t('')}`);
      }
      setErrorSuccess(true);
    } catch (error) {
      console.log(error);
    }
  }, [orderId, t, writerCommandId]);

  useEffect(() => {
    document.title = t('Typix - Détails du texte');
    window.dispatchEvent(new Event('locationchange'));
  }, [t]);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  useEffect(() => {
    if (textForm) {
      setTextTitle(textForm.textTitle);
      setkeyword(textForm.keyword);
      setUrl(textForm.url);
      setTitleTag(textForm.titleTag);
      setMetaDescriptionTag(textForm.metaDescriptionTag);

      setActualTextContent(textForm.actualTextContent);
      const originalText = textForm.actualHtmlContent?.replace(
        new RegExp('style="color: red;"', 'g'),
        'style="color: black;"'
      );
      setActualHtmlContent(originalText);
      setAnalyseText(
        textForm.actualTextContent
          ? textForm.actualTextContent
          : textForm.actualHtmlContent
      );

      let result = textForm.analyseResult && JSON.parse(textForm.analyseResult);
      setAnalyseResult(
        result && typeof result === 'string' ? JSON.parse(result) : result
      );

      setKeywords(
        textForm.semanticGap
          .map((x) => x.word + '(' + x.frequency + ')')
          .join(',\n')
      );
      setList(
        textForm.semanticGap.reduce((acc, curr) => {
          acc[curr.word] = curr.frequency;
          return acc;
        }, {})
      );
    }
  }, [isLoading, textForm]);

  const checkValidation = useCallback(() => {
    if (
      textForm &&
      (textForm.validated ||
        ((textForm.revisionSent || textForm.globalRevisionSent) &&
          !textForm.revisionDone))
    ) {
      setIsDisabled(true);
      return;
    }
    setIsDisabled(false);
    return;
  }, [textForm]);

  const EnableSendRevision = useCallback(() => {
    if (
      textForm &&
      (textForm.validated || textForm.revisionSent || textForm.revisionDisabled)
    ) {
      setIsSendRevisionDisabled(true);
      return;
    }
    setIsSendRevisionDisabled(false);
    return;
  }, [textForm]);

  useEffect(() => checkValidation(), [checkValidation, textForm]);
  useEffect(() => EnableSendRevision(), [EnableSendRevision, textForm]);

  function handleSave() {
    setIsSave(true);
    const list = setKeyWordList(keywords);

    const data = {
      actualHtmlContent,
      actualTextContent,
      analyseResult: analyseResult && JSON.stringify(analyseResult),
      keyword,
      titleTag,
      metaDescriptionTag,
      semanticGap: Object.entries(list || {}).map(([key, value]) => ({
        word: key,
        frequency: value,
      })),
      url: url,
    };
    axiosPrivate
      .put(`/texts/${textForm.id}/client-deliver`, data)
      .then((res) => {
        dispatch(updateForm(res.data));
        setErrorSuccessMsg(`${t('Le texte a été enregistré avec succès')}!`);
        setErrorSuccessImg(animationUrl + '/05_Contenu_livré.gif');
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch(() => {
        setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));

        setIsSuccess(false);
        setErrorSuccess(true);
      });
  }

  function validateText() {
    setIsSave(false);
    let content = actualHtmlContent ? actualHtmlContent : actualTextContent;
    const cleanedHtml = content.replace(/<strong>(.*?)<\/strong>/g, '$1');
    const data = {
      actualHtmlContent: cleanedHtml,
      actualTextContent: actualTextContent,
    };
    axiosPrivate
      .put(`/texts/${textForm.id}/validate`, data)
      .then((res) => {
        setConfirmation(false);
        dispatch(updateForm(res.data));
        dispatch(updateTextForm({ key: 'validated', value: true }));
        dispatch(
          updateTextOfTextList({
            id: textForm.id,
            key: 'validated',
            value: true,
          })
        );
        setErrorSuccessMsg(`${t('Le texte a été validé avec succès')}!`);
        setErrorSuccessImg(animationUrl + '/04_Contenu_validé.gif');

        checkAllValidated();

        setIsSuccess(true);
      })
      .catch(() => {
        setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));

        setIsSuccess(false);
        setErrorSuccess(true);
      });
  }

  if (isLoading || isError) return <Loader />;

  return (
    <div className="content-center">
      <div className="content-center">
        <div className="box-header-top header-info">
          <span
            className="bt-circle me-3"
            onClick={() => {
              history.goBack();
              dispatch(fetchClientOrderDisplay(orderId));
              dispatch(fetchClientTextOrderDisplay(orderId));
              dispatch(fetchClientNotification());
            }}
          ></span>
          <h1>{textTitle}</h1>
        </div>

        <div className="row">
          <div className="col-12 col-md-12">
            <div className="box-wrapper-gray">
              <div className="box-txt">
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => setExpanded(!expanded)}
                >
                  <strong>{t('Détails du texte')} : </strong>{' '}
                  {t('(Titre, mot clé, url, balise, méta-description)')}
                </div>
                <div
                  className="i-13"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setExpanded(!expanded)}
                >
                  <i className={`fa fa-chevron-${expanded ? 'up' : 'down'}`} />
                </div>
              </div>
              {expanded ? (
                <>
                  <div className="row row-mb">
                    <div className="col-12 col-md-6">
                      <div className="label-title">{t('Titre du texte')}</div>
                      <div>
                        <input
                          type="text"
                          className="form-control form-ct"
                          placeholder={t('Nom du texte')}
                          value={textTitle}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="label-title">{t('Mot clé')}</div>
                      <div>
                        <input
                          type="text"
                          className="form-control form-ct"
                          placeholder={t('Mot clé')}
                          value={keyword}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row row-mb">
                    <div className="col-12 col-md-6">
                      <div className="label-title">{t('URL')}</div>
                      <div style={{ position: 'relative' }}>
                        <input
                          type="text"
                          className="form-control form-ct"
                          placeholder="https://www."
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                        />

                        <ClipboardCopy url={url} />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="label-title">{t('Balise titre')}</div>
                      <div>
                        <input
                          type="text"
                          className="form-control form-ct"
                          placeholder={t('Balise titre')}
                          value={titleTag}
                          onChange={(e) => setTitleTag(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="label-title">
                        {t('Balise meta-description')}
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control form-ct"
                          placeholder={t('Balise meta-description')}
                          value={metaDescriptionTag}
                          onChange={(e) =>
                            setMetaDescriptionTag(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            {(textType === 'NON_TRANSLATED' || textType === 'COMBINED') && (
              <>
                <div className="mt-3">
                  <div className="label-title label-text-h">
                    {t('Gap sémantique')} :
                    <CustomTooltip
                      title={
                        <div style={{ marginLeft: '10px' }}>
                          <div className="row">{t('Format souhaité')} : </div>
                          <div className="row">{t('mot-clé (fréquence)')}</div>
                          <div className="row">
                            {t('Séparez les mots-clés par un " Enter ".')}
                          </div>
                          <div className="row">
                            ---------------------------------
                          </div>
                          <div className="row">{t('Exemple')} : </div>
                          <div className="row">{t('mot-clé')}1 (2)</div>
                          <div className="row">{t('mot-clé')}2 (1)</div>
                          <div className="row">{t('mot-clé')}3 (1)</div>
                          <div className="row">{t('mot-clé')}4 (2)</div>
                        </div>
                      }
                      placement="top"
                    >
                      <button
                        type="button"
                        className="btn-circle"
                        style={{ marginLeft: 5 }}
                      ></button>
                    </CustomTooltip>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-5 box-wrapper">
                    <textarea
                      className=" col-12 box-list-text"
                      value={keywords}
                    >
                      <div></div>
                    </textarea>
                  </div>
                  <div className="col-12 col-md-3">
                    <div
                      className="box-wrapper box-list-text"
                      style={{ height: '284px; !important' }}
                    >
                      <div className="header-title mb-0">
                        {t('Résultat sémantique')}
                      </div>
                      <div className="sub-header-title">
                        {t('Enrichissement sémantique')} :
                      </div>
                      <div className="box-graph">
                        {loading && <Loader />}
                        {analyseResult &&
                        analyseResult.semanticEnrichment >= 0 ? (
                          <div className="d-flex justify-content-center">
                            <CustomHighcharts
                              value={analyseResult.semanticEnrichment}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <button
                          className="bt-submit-y"
                          style={{ margin: '0 auto' }}
                          onClick={() =>
                            DownloadOneTextWordType(textForm.id, user)
                          }
                        >
                          {t('Télécharger le document')}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="box-wrapper">
                      <div className="header-title mb-0">
                        {t('Mots clés manquants')} :
                      </div>
                      <br />
                      <div className="box-list-text box-motcle">
                        {loading && <Loader />}
                        <ul>
                          {analyseResult &&
                          analyseResult.greenWordsByFreq
                            .greenWordsWithRemainingFreq &&
                          Object.keys(
                            analyseResult.greenWordsByFreq
                              .greenWordsWithRemainingFreq
                          ).length > 0 ? (
                            <div>
                              <b>
                                {t(
                                  'Ajout de mots-clés avec la fréquence restante'
                                )}{' '}
                                :{' '}
                              </b>
                              <p>
                                {Object.keys(
                                  analyseResult.greenWordsByFreq
                                    .greenWordsWithRemainingFreq
                                ).map((item, key) => (
                                  <li key={key}>
                                    {' '}
                                    <span>
                                      {' '}
                                      {item} (
                                      <span
                                        style={{
                                          textDecorationLine: 'line-through',
                                        }}
                                      >
                                        {list[item]}
                                      </span>
                                      ,
                                      {
                                        analyseResult.greenWordsByFreq
                                          .greenWordsWithRemainingFreq[item]
                                      }
                                      ){' '}
                                    </span>{' '}
                                  </li>
                                ))}
                              </p>
                            </div>
                          ) : null}
                          {analyseResult &&
                          analyseResult.remainingKeywords &&
                          Object.keys(analyseResult.remainingKeywords).length >
                            0 ? (
                            <div>
                              <b>{t('Mots clés restants')} :</b>
                              <p>
                                {Object.keys(
                                  analyseResult.remainingKeywords
                                ).map((item, key) => (
                                  <li key={key}>
                                    {' '}
                                    <span>
                                      {' '}
                                      {item} (
                                      {analyseResult.remainingKeywords[item]}){' '}
                                    </span>{' '}
                                  </li>
                                ))}
                              </p>
                            </div>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {(textForm.globalRevisionSent || textForm.revisionSent) &&
              textForm.revisionText && (
                <RevisionDetails
                  t={t}
                  textId={textForm.id}
                  revisionRequestAt={textForm.revisionRequestAt}
                  revisionText={textForm.revisionText}
                  revisionFileName={textForm.revisionFileName}
                />
              )}

            <div className="row mt-2">
              <div className="col-12 col-md-12">
                <div>
                  <div className="label-title label-text-h">
                    {t('Contenu rédigé')}
                  </div>

                  <div className="box-wrapper-editor">
                    <CustomEditor
                      //disabled={isRevisionSent || textForm.validated}
                      isReadOnly={!(isRevisionSent || textForm.validated)}
                      content={
                        orderType !== 'PREMIUM'
                          ? actualTextContent
                          : actualHtmlContent
                      }
                      onChange={(value, editor) => {
                        let textValue = editor.getContent({ format: 'text' });
                        if (!textForm.validated)
                          autoSaveText(
                            textValue,
                            value,
                            textForm,
                            textForm.initialHtmlContent,
                            textForm.initialTextContent,
                            SavingState,
                            setSaving,
                            setTimer,
                            timer,
                            actualTextContent,
                            orderType
                          );

                        if (orderType !== 'PREMIUM') {
                          setActualTextContent(value);
                          setAnalyseText(textValue);
                        } else {
                          setActualHtmlContent(value);
                          setActualTextContent(textValue);
                          setAnalyseText(textValue);
                        }

                        // setActualTextWordCount(
                        //   editor.plugins.wordcount.getCount()
                        // );
                      }}
                      t={t}
                      orderType={orderType}
                    />
                  </div>
                </div>
                <AutoSaveDisplay saving={saving} />
              </div>
            </div>

            <div className="row ">
              <div className="col-12 col-md-6">
                <div className="box-botom-flex">
                  <div style={{ paddingLeft: 10, paddingTop: 10 }}>
                    <div>
                      <div className="box-list-dropdown">
                        {t('Langue')}
                        <div
                          className="dropdown"
                          style={{ width: 170, marginLeft: 5 }}
                        >
                          <button
                            className="btn dropdown-toggle dropdown-custom w-100"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {t(capitalize(language.language.toLowerCase()))}
                            <span>
                              <ChevronDownIcon />
                            </span>
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            {' '}
                            {ANALYSELANGAUGESLIST.map((item, index) => (
                              <li key={index} onClick={() => setLanguage(item)}>
                                <button className="dropdown-item">
                                  {t(
                                    capitalize(item.language.toLowerCase())
                                  ).toUpperCase()}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="box-botom-flex">
                  <div
                    className="d-flex "
                    style={{ paddingLeft: 10, paddingTop: 10 }}
                  >
                    <div>
                      <div className="d-flex justify-content-end">
                        <span className="me-2">
                          {isDisabled ? (
                            <button className="bt-submit-disabled-y">
                              {t('Valider le texte')}
                            </button>
                          ) : (
                            <button
                              className="bt-submit-y"
                              style={{
                                background: '#43B929',
                                color: 'white',
                              }}
                              onClick={() => {
                                setConfirmationMsg(
                                  t(
                                    'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.'
                                  )
                                );
                                setConfirmation(true);
                              }}
                            >
                              {t('Valider le texte')}
                            </button>
                          )}
                        </span>

                        {orderType === 'PREMIUM' ? (
                          <span className="me-2">
                            {isSendRevisionDisabled ? (
                              <button className="bt-submit-disabled-y">
                                {t('Demander une révision')}
                              </button>
                            ) : (
                              <button
                                className="bt-submit-y"
                                onClick={() => setShowRevisionModal(true)}
                              >
                                {t('Demander une révision')}
                              </button>
                            )}
                          </span>
                        ) : (
                          ''
                        )}

                        {(textType === 'NON_TRANSLATED' ||
                          textType === 'COMBINED') && (
                          <span className="me-2">
                            <AnalyseText
                              orderType={orderType}
                              keywords={keywords}
                              initialText={textForm.initialTextContent}
                              text={analyseText}
                              content={
                                orderType === 'PREMIUM'
                                  ? actualHtmlContent
                                  : actualTextContent
                              }
                              setContent={(value) => {
                                if (orderType === 'PREMIUM') {
                                  setActualHtmlContent(value);
                                } else {
                                  setActualTextContent(value);
                                }
                              }}
                              language={language}
                              setList={setList}
                              setResult={(value) => setAnalyseResult(value)}
                              setLoading={(value) => setLoading(value)}
                              disabled={isDisabled}
                            />
                          </span>
                        )}
                        <span className="me-2">
                          {isDisabled ? (
                            <button
                              className="bt-submit-disabled-y"
                              type="submit"
                            >
                              {t('Sauvegarder')}
                            </button>
                          ) : (
                            <button
                              className="bt-submit-y"
                              type="submit"
                              onClick={handleSave}
                            >
                              {t('Sauvegarder')}
                            </button>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RevisionRequestModal
        textId={textForm.id}
        showRevisionModal={showRevisionModal}
        requestText={requestText}
        selectedFile={selectedFile}
        handleClose={() => {
          setShowRevisionModal(false);
          setRequestText(null);
          setSelectedFile(null);
        }}
        setRequestText={(value) => setRequestText(value)}
        setSelectedFile={(value) => setSelectedFile(value)}
      />
      <ComparisonModal
        open={showComparison}
        initialText={textForm.initialTextContent}
        text={actualTextContent}
        handleClose={() => setShowComparison(false)}
      />
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => {
          isSuccess && !isSave && history.goBack();
          setErrorSuccess(false);
        }}
        success={isSuccess}
        orderId={orderId}
        user={user}
        t={t}
        allTextsValidated={isAllValidated}
        partTextValidated={isPartValidated}
        userName={userName}
        nbParts={nbParts}
        nbValidatedParts={nbValidatedParts}
        writerCommandId={writerCommandId}
        toggle={() => setErrorSuccess(!errorSuccess)}
        imageUrl={errorSuccessImg}
      />
      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={validateText}
      />
    </div>
  );
}

export default ClientTextDisplay;
