export const romanianTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'Liniile de comandă au fost actualizate cu succes!',
  'Le texte a été enregistré avec succès':
    'Textul a fost înregistrat cu succes',
  'La traduction a été enregistrée avec succès':
    'Traducerea a fost salvată cu succes',
  'Texte + Traduction enregistré avec succès':
    'Text + Traducerea a fost salvată cu succes',
  'Nombre de mots': 'Număr de cuvinte',
  Lingala: 'Lingala',
  'Prénom/Nom du client': 'Numele și prenumele clientului',
  Bashkir: 'Bashkir',
  "La date doit être ultérieure à aujourd'hui.":
    'Data trebuie să fie mai târziu de astăzi.',
  'Contenu rédigé': 'Conținut scris',
  'Nom/prénom': 'Nume de familie/prenume',
  'Mot de passe incorrect': 'Parolă incorectă',
  Institutions: 'Instituții',
  'Veuillez donner un descriptif au document téléchargé':
    'Vă rugăm să oferiți o descriere a documentului descărcat',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Următorul',
  Kannada: 'Kannada',
  'Séparer les mots clés par un "enter"':
    'Separați cuvintele cheie cu un "enter".',
  Allemand: 'Germană',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'un e-mail conținând un link de resetare a parolei a fost trimis la adresa dvs. de e-mail',
  'Chef edito': 'Redactor șef',
  Croate: 'Croată',
  'Nb de textes': 'Nr. de texte',
  Violet: 'Violet',
  'Ce champs doit être sup ou égale nombre de texte':
    'Acest câmp trebuie să fie mai mare sau egal cu numărul de text',
  'a été activé avec succès': 'a fost activat cu succes',
  Népalais: 'Nepaleză',
  'Nombre de FAQ': 'Numărul de întrebări frecvente',
  Urdu: 'Urdu',
  'Analyses restantes': 'Analize rămase',
  Indonésien: 'Indoneziană',
  Breton: 'Breton',
  Livrés: 'Livrat',
  Associations: 'Asociații',
  'Le client': 'Clientul',
  'Révision demandée': 'Revizuire solicitată',
  "Une erreur s'est produite, veuillez réessayer":
    'A apărut o eroare, vă rugăm să încercați din nou',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Actualizare neautorizată, deoarece textele au fost livrate',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'Pentru a adăuga text (optimizarea conținutului existent)',
  Gaélique: 'Gaelic',
  'La ligne de commande pour': 'Linia de comandă pentru',
  'Business/Management': 'Afaceri/Management',
  Serbe: 'Sârbă',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Vă rugăm să indicați mai jos solicitările dumneavoastră de modificări, ținând cont de condițiile noastre de călcare',
  'rédacteurs trouvés': 'scriitori găsiți',
  'Kirghiz ; Kirghiz': 'Kirghiz; Kirghiz',
  Différence: 'Diferență',
  'en cours': 'în curs de desfășurare',
  'Sami du Nord': 'Sami de Nord',
  Comparer: 'Comparați',
  Lituanien: 'Lituaniene',
  'Typix - Mon tableau de bord': 'Typix - Tabloul meu de bord',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Trebuie să aibă cel puțin 8 caractere, cu majuscule, cifre, caractere speciale și să nu fie identic cu numele.',
  Webmaster: 'Webmaster',
  'Veuillez saisir des champs valides':
    'Vă rugăm să introduceți câmpuri valide',
  Basque: 'Bască',
  'Exporter .csv': 'Export .csv',
  'Confirmer le nouveau mot de passe': 'Confirmați noua parolă',
  "Pas d'options": 'Nu există opțiuni',
  'Banque/Assurance': 'Bănci/Asigurări',
  'Vos demandes': 'Cererile dumneavoastră',
  'Coût d\u2019achat': 'Costul de achiziție',
  Tamil: 'Tamil',
  'Le texte a été supprimé avec succès': 'Textul a fost șters cu succes',
  'Mes Commandes': 'Comenzile mele',
  Zoulou: 'Zulu',
  Afficher: 'Vezi',
  Texte: 'Text',
  Islandais: 'Islandeză',
  Russe: 'Rusă',
  'Tous les textes': 'Toate textele',
  Czech: 'Cehia',
  'Comparaison hors ligne': 'Comparație offline',
  Téléphonie: 'Telefonie',
  'Valider le texte': 'Validarea textului',
  'Votre text ne de doit pas dépasser 500 mots':
    'Textul dumneavoastră nu trebuie să depășească 500 de cuvinte',
  'Coquilles (double espace, « s »\u2026)': 'Scoici (spațiu dublu, "s"...)',
  'le consultant': 'consultantul',
  Coréen: 'Coreeană',
  'Nb de Silo': 'Nr. de silozuri',
  Validés: 'Validat',
  'non attribuées': 'nealocate',
  Néerlandais: 'Olandeză',
  'Voulez-vous vraiment supprimer': 'Chiar vrei să ștergi',
  Rundi: 'Rundi',
  Regional: 'Regional',
  'Compte client': 'Cont client',
  Global: 'Global',
  'Tous les rôles': 'Toate rolurile',
  Musique: 'Muzică',
  Perso: 'Personal',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Se pare că nu mai aveți credit de analiză',
  Bambara: 'Bambara',
  Sortir: 'Ieși afară.',
  optionel: 'opțional',
  'Temps restant': 'Timp rămas',
  Ukrainien: 'Ucraineană',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa; Chewa; Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'Cererea dumneavoastră de revizuire a fost trimisă.',
  'Typix - Créer rédacteur': 'Typix - Creați un scriitor',
  "n'existe pas": 'nu există',
  Hindi: 'Hindi',
  'ne peut pas être supprimé car il est lié à des sites web':
    'nu poate fi șters, deoarece este legat de site-uri web',
  'Commandes en cours': 'Comenzi în curs de execuție',
  'Textes livrés': 'Texte livrate',
  Validation: 'Validare',
  'Non attribuées': 'Nealocat',
  'Voulez-vous vraiment désactiver': 'Chiar doriți să dezactivați',
  'Catalan ; Valencien': 'catalană; valenciană',
  'Nombre de textes restants': 'Numărul de texte rămase',
  'Ne doit pas contenir votre nom': 'Nu trebuie să conțină numele dvs.',
  Documentation: 'Documentație',
  Kazakh: 'Kazahstan',
  'Numéro invalide': 'Număr invalid',
  'Veuillez saisir un texte ou importer un fichier.':
    'Vă rugăm să introduceți un text sau să importați un fișier.',
  'Nb de FAQ': 'Nr. de întrebări frecvente',
  Analyser: 'Analizați',
  Turc: 'Turcă',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Orice cerere care nu este specificată în memoriu',
  Wallon: 'Valonia',
  Birmane: 'Birmaneză',
  Irlandais: 'Irlandezii',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Resetați-vă parola',
  Cuisine: 'Bucătărie',
  'Haïtien ; créole haïtien': 'haitiană; creolă haitiană',
  'Vous ne pouvez pas supprimer le rédacteur': 'Nu puteți șterge scriitorul',
  Validé: 'Validat',
  "cet email n'existe pas": 'acest e-mail nu există',
  Historique: 'Istorie',
  Confirmer: 'Confirmați',
  'à cocher si le nombre de mots est différent par texte':
    'se bifează în cazul în care numărul de cuvinte este diferit pentru fiecare text.',
  'Fiche du site web ': 'Fișa site-ului web',
  Désactiver: 'Dezactivați',
  'Travaux/BTP': 'Lucrări/Construcții',
  'La fiche du website': 'Fișa site-ului',
  'Si le contenu ne respecte pas les règles du brief':
    'În cazul în care conținutul nu respectă regulile briefului',
  Norvégien: 'Norvegiană',
  '0 rédacteurs': '0 scriitori',
  indisponible: 'indisponibil',
  Venda: 'Venda',
  Maori: 'Maori',
  'Textes revisés': 'Texte revizuite',
  'Status texte': 'Starea textului',
  'textes livrés en attente de validation':
    'texte livrate în așteptarea validării',
  'Typix - Mon Profile': 'Typix - Profilul meu',
  'le client': 'clientul',
  Romanche: 'Romanche',
  'URL du site': 'URL-ul site-ului',
  'nouvelles notifications': 'notificări noi',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; Maldivian',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Ești sigur că vrei să salvezi textul',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Vă rugăm să adăugați un text inițial pentru a salva textul ca proiect.',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'Contractul pentru client',
  rédacteurs: 'scriitori',
  'Ce champ est obligatoire': 'Acest câmp este obligatoriu',
  'Tarif/Mot': 'Tarif/cuvânt',
  Tadjik: 'Tajik',
  'Réf devis': 'Ref. citat',
  'Rechercher un rédacteur': 'Căutați un scriitor',
  Albanais: 'Albaneză',
  Galicien: 'Galiciană',
  Disponibilité: 'Disponibilitate',
  'Les deux mots de passe ne sonts pas identiques':
    'Cele două parole nu sunt identice',
  Tibétain: 'Tibetan',
  'Typix - Gestion des utilisateurs': 'Typix - Managementul utilizatorilor',
  'Ossétien ; Ossétique': 'osețiană; osetic',
  Tswana: 'Tswana',
  'Ajouter un client': 'Adăugați un client',
  'Séparez les mots-clés par un " Enter ".':
    'Separați cuvintele cheie cu un "Enter".',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: 'Teme',
  'Nb mots/texte': 'Nr. de cuvinte/text',
  Commerce: 'Comerț',
  'Voir les commandes': 'Vezi comenzile',
  'Nombre de mots par texte': 'Numărul de cuvinte pe text',
  'Lien drive...': 'Link drive...',
  'Hollandais ; Flamand': 'olandeză; flamandă',
  'Mon profil': 'Profilul meu',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'Acest câmp trebuie să fie mai mare decât 0, deoarece facturarea nu este reală',
  'Le texte a été validé avec succès': 'Textul a fost validat cu succes',
  Jeux: 'Jocuri',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Chiar doriți să eliminați linia de comandă pentru',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, Nord; Ndebele Nord; Ndebele Nord',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'Slavonă ecleziastică; Slavonă veche; Slavonă ecleziastică; Bulgară veche; Slavonă veche ecleziastică',
  Swahili: 'Swahili',
  Voyage: 'Călătorie',
  'Nombre de mot à ajouter': 'Numărul de cuvinte de adăugat',
  'Liste des rédacteurs': 'Lista scriitori',
  'le rédacteur': 'scriitorul',
  Kanuri: 'Kanuri',
  rédacteur: 'scriitor',
  Tatar: 'Tatar',
  'Roumain ; Moldave ; Moldovan': 'română; moldovenească',
  'Le texte initial de': 'Textul original al',
  'Mail clients': 'Poșta clientului',
  'Le contrat de': 'Contractul de',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'cel puțin o literă minusculă și una majusculă, un caracter special și un număr',
  Transport: 'Transport',
  'Nombre total de mots (existants et ajoutés) :':
    'Numărul total de cuvinte (existente și adăugate) :',
  'Rechercher par mail, prénom, nom, site...':
    'Căutați după e-mail, nume, prenume, nume de familie, site...',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Numărul de cuvinte care trebuie adăugate (Min/Max) :',
  Compte: 'Cont',
  'Tourisme/Loisirs': 'Turism/Leasing',
  'Nouveau texte': 'Text nou',
  Max: 'Max',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; groenlandeză',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Oops...! Există una sau mai multe erori în fereastra de decalaj semantic. Vă rugăm să verificați conținutul acesteia pentru a o/le corecta și să retrimiteți textul dumneavoastră.',
  'ne peut pas être supprimé pour le moment':
    'nu pot fi șterse în acest moment',
  'Le texte': 'Textul',
  URL: 'URL',
  "Un utilisateur existe déjà avec l'email":
    'Există deja un utilizator cu adresa de e-mail',
  Cree: 'Cree',
  Prénom: 'Prenume',
  'commandes prête': 'comenzi pregătite',
  Réinitialiser: 'Resetare',
  'Veuillez fournir une date valide': 'Vă rugăm să furnizați o dată validă',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'luxemburgheză; Letzeburgesch',
  Suédois: 'Suedeză',
  'Confirmer le mot de passe': 'Confirmați parola',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Acest câmp este obligatoriu și poate conține numai numere',
  Sciences: 'Știință',
  Tchétchène: 'Cecenă',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    'este singurul client pentru un site, trebuie adăugate altele pentru a-l elimina.',
  'Le mot de passe de confirmation ne correspond pas':
    'Parola de confirmare nu se potrivește',
  Swati: 'Swati',
  Danois: 'Daneză',
  Sauvegarder: 'Salvați',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Vă rugăm să modificați următoarele informații: titlul și/sau cuvântul cheie',
  'importer un document': 'importați un document',
  Maltais: 'Malteză',
  'Nb de mots': 'Nr. de cuvinte',
  'Les données fournies sont invalides': 'Datele furnizate nu sunt valabile',
  'Livraison estimée': 'Livrare estimată',
  'Grec moderne (1453-)': 'Greacă modernă (1453-)',
  Avestan: 'Avestan',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Numărul total de cuvinte adăugate',
  'le contenu présente des inexactitudes': 'conținutul conține inexactități',
  Finnois: 'Finlandeză',
  'Ajouter un admin': 'Adăugați un administrator',
  'Min/Max': 'Min/Max',
  'Modifier le consultant': 'Modificarea consultantului',
  'Liste des clients': 'Lista de clienți',
  'Nom client': 'Numele clientului',
  Vert: 'Verde',
  'Mots clés manquants': 'Cuvinte cheie lipsă',
  Lao: 'Lao',
  Somali: 'Somaleză',
  'Nb de silo par texte': 'Nr. de silozuri pe text',
  Italien: 'Italian',
  'La commande a été mis à jour avec succès !':
    'Comanda a fost actualizată cu succes!',
  Bosniaque: 'Bosniacă',
  'Thématiques d\u2019expertise': 'Teme de expertiză',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Aceasta este parola pe care ați uitat-o',
  'a été désactivé avec succès': 'a fost dezactivat cu succes',
  Kongo: 'Kongo',
  'Postuler à la commande': 'Solicitarea ordinului',
  'mot-clé': 'cuvânt cheie',
  Abkhazien: 'Abhazia',
  Mongolien: 'Mongolă',
  'Typix - Fiche site web': 'Typix - Website',
  'Mail envoyé': 'Poșta trimisă',
  Géorgien: 'Georgian',
  'Si des phrases ne sont pas dans un français correct':
    'Dacă propozițiile nu sunt în limba franceză corectă',
  'En cours': 'În curs de desfășurare',
  voir: 'a se vedea',
  "Date d'ajout": 'Data adăugării',
  'Tarif au mot': 'Tarif pe cuvânt',
  Thai: 'Thai',
  'Détails du texte': 'Detaliile textului',
  'Commandes en retard': 'Comenzi întârziate',
  disponible: 'disponibil',
  Droit: 'Legea',
  Azerbaïdjanais: 'Azerbaidjan',
  'Ajouter un consultant': 'Adăugați un consultant',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'La reluarea conținutului, vor fi acceptate numai următoarele elemente',
  'doit comprendre au moins 8 charactères':
    'trebuie să conțină cel puțin 8 caractere',
  'Titre de la commande': 'Titlul comenzii',
  'Langues bihari': 'Bihari languages',
  'Livraison estimé au': 'Livrare estimată până la',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    'Numărul de texte din comandă nu poate fi mai mic decât numărul de texte atribuite scriitorului (scriitorilor). Vă rugăm să modificați mai întâi informațiile din linia de comandă',
  'Date de livraison': 'Data de livrare',
  'Voulez-vous vraiment supprimer le contrat de': 'Chiar vreți să desființați',
  'Facturation au réel': 'Facturarea efectivă',
  'Gap sémantique': 'Diferență semantică',
  Estonien: 'Estoniană',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Costul maxim de achiziție / cuvânt',
  'Détail de la commande': 'Detalii de comandă',
  'Export en cours de téléchargement\u2026':
    'Exportul este în curs de încărcare...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    'Acest câmp este obligatoriu și trebuie să fie mai mic sau egal cu Numărul total de texte din comandă.',
  Arabe: 'Arabă',
  Bislama: 'Bislama',
  'Mail client': 'Poșta clientului',
  '(Titre, mot clé, url, balise, méta-description)':
    '(Titlu, cuvinte cheie, url, tag, meta-descriere)',
  'Typix - Nouvelle commande': 'Typix - Comandă nouă',
  'Révision en attente': 'Revizuire în așteptare',
  'Le consultant': 'Consultantul',
  Entreprise: 'Compania',
  Aragonais: 'Aragoneză',
  Kashmiri: 'Kashmiri',
  'a été activé': 'a fost activat',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Asociația internațională a limbilor auxiliare)',
  Actualités: 'Știri',
  'Votre décision a été prise en compte avec succès':
    'Decizia dumneavoastră a fost luată în considerare cu succes',
  Afrikaans: 'Afrikaans',
  'contenu en attente de vérification client':
    'conținut în așteptarea verificării clientului',
  'Fiche du rédacteur': 'Fișa scriitor',
  Outillage: 'Unelte',
  'Typix - Détails de la commande': 'Typix - Detalii de comandă',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Adăugați sau completați conținutul dumneavoastră mai jos:',
  Ewe: 'Oaie',
  'contenu en attente de repasse rédacteur':
    'scriitor de redare a conținutului în așteptare',
  'La commande a été supprimé avec succès': 'Comanda a fost ștearsă cu succes',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Expresii sau fraze franceze care nu vi se potrivesc',
  Corse: 'Corsica',
  Modifier: 'Editați',
  'Nb de GMB': 'Nr. de GMB',
  Oromo: 'Oromo',
  Polish: 'Poloneză',
  'Insérez votre gap sémantique avec l’occurrence':
    'Introduceți decalajul semantic cu apariția',
  'Nb de textes à optimiser': 'Numărul de texte care trebuie optimizate',
  Mode: 'Mod',
  Akan: 'Akan',
  'Révisions effectués': 'Revizuiri efectuate',
  'Modifier l\u2019admin': 'Editați administratorul',
  'Télécharger le document': 'Descărcați documentul',
  Hausa: 'Hausa',
  'Notes rédacteurs': 'Note ale scriitori',
  Quechua: 'Quechua',
  Féroïen: 'Feroe',
  'Modifier le rédacteur': 'Schimbați scriitorul',
  Mail: 'Mail',
  Rédacteurs: 'Scriitori',
  'Typix - Détails du texte': 'Typix - Detalii text',
  Hongrois: 'Maghiară',
  'Bon pour livraison': 'Bun pentru livrare',
  'Au réel': 'În lumea reală',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Ebraică',
  'Nombre total de mots ajoutés :': 'Numărul total de cuvinte adăugate :',
  Komi: 'Komi',
  'Doit être inférieur au nombre maximum':
    'Trebuie să fie mai mic decât numărul maxim',
  'Nom du texte': 'Denumirea textului',
  'Ajouter un rédacteur': 'Adăugați un scriitor',
  Ou: 'Sau',
  Utilisateurs: 'Utilizatori',
  Heures: 'Ore',
  'Santé/Bien-être': 'Sănătate/Bunăstare',
  'Interlingue ; Occidental': 'Interlingual; occidental',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Letonă',
  Anglais: 'Engleză',
  Envoyer: 'Trimiteți',
  'Tableau de bord': 'Tabloul de bord',
  'Demande de révision': 'Cerere de reexaminare',
  'Résultat sémantique': 'Rezultat semantic',
  'Chercher un rédacteur': 'Căutați un scriitor',
  'Ce champs est obligatoire': 'Acest câmp este obligatoriu',
  Rédaction: 'scriitorial',
  'Modifier le client': 'Modificarea clientului',
  'Voulez-vous vraiment supprimer le rédacteur':
    'Chiar doriți să ștergeți scriitorul',
  Titre: 'Titlu',
  Langue: 'Limba',
  'Mot clé': 'Cuvinte cheie',
  'Les demandes suivantes ne seront pas prises en compte':
    'Nu vor fi luate în considerare următoarele cereri',
  'délai dépassé': 'termen depășit',
  Tchouvache: 'Tchouvache',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Contract existent, vă rugăm să modificați informațiile',
  Igbo: 'Igbo',
  Yiddish: 'Yiddish',
  'Limburgan ; Limburger ; Limburgish':
    'Limburgan; Limburger; Limburgish; Limburgish',
  'Commandes validées': 'Comenzi validate',
  'listeRedacteur-': 'listRedactor-',
  Étape: 'Pasul',
  'Energie/Environnement': 'Energie/Mediu',
  Min: 'Min',
  Gujarati: 'Gujarati',
  'ne doit pas inclure votre nom/prénom':
    'nu trebuie să includă numele/prenumele dumneavoastră',
  'Typix - Fiche rédacteur': 'Typix - Dosarul scriitorului',
  'révisions effectuées en attentes de validation':
    'revizuiri efectuate în așteptarea validării',
  'Textes validés': 'Texte validate',
  Art: 'Artă',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Versatilitate',
  Sundanese: 'Sundaneză',
  'Nb total de textes': 'Numărul total de texte',
  'Nombre de mots gérés': 'Numărul de cuvinte gestionate',
  "L'émail": 'Email',
  'Afficher les résultats': 'Afișați rezultatele',
  'Frison occidental': 'Frisonul de Vest',
  'Modification effectuée avec succès': 'Modificare finalizată cu succes',
  'Balise titre': 'Eticheta de titlu',
  'Typix - Mon profil': 'Typix - Profilul meu',
  'email invalide': 'e-mail invalid',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Vă rugăm să adăugați o adresă URL pentru a putea salva textul ca draft',
  Macédonien: 'Macedoneană',
  'Votre candidature a bien été envoyée':
    'Cererea dumneavoastră a fost trimisă',
  'E-mail': 'E-mail',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Adăugați sau completați conținutul dvs. mai jos',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Adăugarea cuvintelor cheie cu frecvență restantă',
  'Fiche du site web': 'Fișa site-ului web',
  Contacter: 'Contactați',
  Fidjien: 'Fijian',
  Déconnexion: 'Deconectare',
  email: 'e-mail',
  'Nombre de textes': 'Număr de texte',
  'Enregistrer et informer client': 'Înregistrarea și informarea clienților',
  'Date de validation': 'Data validării',
  'Tonga (Îles Tonga)': 'Tonga (Insulele Tonga)',
  Javanais: 'Javaneză',
  Portugais: 'Portugheză',
  Biélorusse: 'Bielorusă',
  'Pour ajouter un nouveau texte': 'Pentru a adăuga un text nou',
  'Enfant/Puéri': 'Copilul/Puperiul',
  'Mots clés': 'Cuvinte cheie',
  Emploi: 'Ocuparea forței de muncă',
  'Voulez-vous vraiment supprimer la commande de': 'Chiar vrei să elimini',
  'Mot clé': 'Cuvânt cheie',
  Consultants: 'Consultanți',
  Volapük: 'Volapük',
  Services: 'Servicii',
  'mot-clé (fréquence)': 'cuvânt cheie (frecvență)',
  'Ne plus afficher ce message': 'Nu mai afișați acest mesaj',
  Consultant: 'Consultant',
  "Une erreur s'est produite": 'A apărut o eroare',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Cel puțin 8 caractere cu o literă majusculă, un număr, un caracter special',
  'Pushto ; Pashto': 'Pushto; Pashto',
  Français: 'Franceză',
  validées: 'validat',
  'Url du site': 'Url a site-ului',
  Précédent: 'Anterior',
  'car celui-ci a déjà rédigé des textes':
    'deoarece acesta din urmă a elaborat deja texte',
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Schițe',
  Ido: 'Ido',
  'Contenu disponible': 'Conținut disponibil',
  Enseignement: 'Predare',
  'a été livré avec succès': 'a fost livrat cu succes',
  Chinois: 'Chineză',
  Vietnamien: 'Vietnameză',
  'Nouveau client': 'Client nou',
  'Votre profil a été mis à jour avec succès.':
    'Profilul dvs. a fost actualizat cu succes.',
  Consignes: 'Instrucțiuni',
  'Mot de passe': 'Parola',
  Annuler: 'Anulează',
  Non: 'Nu',
  Nom: 'Nume',
  Guarani: 'Guarani',
  "il y'a des commandes liés à au site web":
    'există comenzi legate de site-ul web',
  Score: 'Scor',
  'Mot de passe mis à jour avec succès': 'Parola a fost actualizată cu succes',
  Latin: 'Latină',
  Technologie: 'Tehnologie',
  Internet: 'Internet',
  Nauru: 'Nauru',
  'a été créé avec succès': 'a fost creat cu succes',
  Roumain: 'Românesc',
  Assamais: 'Assamese',
  'Khmer central': 'Central Khmer',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Punjabi; Punjabi',
  'Analyse/Limite': 'Analiză/Limită',
  Grec: 'Greacă',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'Proiectele mele',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'cuvânt cheie1 (2)\ncuvânt cheie2 (1)\ncuvânt cheie3 (1)\ncuvânt-cheie4 (2)\n...',
  'ajouter un texte': 'adăugați un text',
  'Détail de la prestation': 'Detalii despre serviciu',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    'Aveți posibilitatea de a exporta raportul pentru una sau mai multe comenzi. Pentru a exporta toate comenzile unui client, trebuie doar să faceți clic pe pictograma galbenă de descărcare.',
  'Gestion des utilisateurs': 'Gestionarea utilizatorilor',
  'livré et inférieur au nombre de texte non affecté':
    'livrate și mai puține decât numărul de texte neatribuite',
  'Date deadline validation client': 'Termenul de validare a clientului',
  Aymara: 'Aymara',
  Validées: 'Validat',
  Notes: 'Note',
  'Nombre de GMB': 'Numărul de MGB',
  'résultats sur': 'rezultatele privind',
  'Prénom / Nom du client': 'Prenumele / Numele clientului',
  'Tout télécharger': 'Descărcați toate',
  'Champs obligatoires': 'Câmpuri obligatorii',
  Éditer: 'Editați',
  'Coller le texte initial dans le champs ci-dessous':
    'Lipiți textul original în câmpul de mai jos',
  'ID rédacteur': 'ID scriitor',
  Arménien: 'Armenească',
  'Grande distribution': 'Distribuție pe scară largă',
  Welsh: 'Țara Galilor',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Îmbogățirea semantică',
  'Détail de la prestation du contenu': 'Detalii privind livrarea conținutului',
  Noir: 'Negru',
  'Ajouter un texte': 'Adăugați un text',
  Traduction: 'Traducere',
  'Immo/Defisc': 'Proprietate / Impozite',
  'Brief détaillé': 'Memoriu detaliat',
  'Séparez les mots-clés par un Enter .':
    'Separați cuvintele cheie cu un Enter .',
  Prix: 'Premii',
  'mot de passe oublié': 'Parolă uitată',
  'Ndebele, South ; South Ndebele': 'Ndebele, Sud; Ndebele Sud; Ndebele Sud',
  'Demander une révision': 'Solicitați o recenzie',
  'Demander une repasse globale': 'Solicitați o revizuire cuprinzătoare',
  'Sotho, Southern': 'Sotho, Sudic',
  'Le titre de la commande existe déjà': 'Titlul comenzii există deja',
  'Félicitations !': 'Felicitări!',
  Kurde: 'Kurdish',
  Adresse: 'Adresa',
  Espagnol: 'Spaniolă',
  Ndonga: 'Ndonga',
  'En retard': 'Târziu',
  'a été ajouté avec succès': 'a fost adăugat cu succes',
  Commandes: 'comenzi',
  'a été mis en brouillon': 'a fost redactat',
  'Rédacteur attribué': 'scriitor desemnat',
  Rechercher: 'Căutare',
  'Nouvelle commande': 'Comandă nouă',
  'Commandes non attribuées': 'Comenzi nealocate',
  'Le texte a été mis en brouillon': 'Textul a fost redactat',
  'Contact edito': 'Contactați edito',
  Langues: 'Limbi',
  Orange: 'Portocaliu',
  'Nynorsk norvégien': 'Norvegiană Nynorsk',
  Shona: 'Shona',
  Turkmène: 'Turkmeni',
  'contenu repassé, en attente validation client':
    'conținut călcat, în așteptarea validării clientului',
  'Nombre de mots rédigés': 'Numărul de cuvinte scrise',
  Malay: 'Malay',
  'Nombre de Silo': 'Numărul de silozuri',
  Oui: 'Da',
  Automobile: 'Automotive',
  Résultats: 'Rezultate',
  Afar: 'Afar',
  'Tâche - id tâche': 'Sarcină - ID-ul sarcinii',
  'Ce champ est obligatoire doit être supérieur à 0':
    'Acest câmp este obligatoriu și trebuie să fie mai mare decât 0',
  'Vous êtes sûr': 'Ești sigur că',
  'Réinitialiser votre mot de passe': 'Resetați-vă parola',
  Sindhi: 'Sindhi',
  Bengali: 'Bengali',
  'Bokmål, norvégien ; Bokmål norvégien':
    'Bokmål, norvegiană; norvegiană Bokmål',
  'Nombre de Jours': 'Număr de zile',
  'Min/Max mots': 'Cuvinte Min/Max',
  Slovène: 'Slovenă',
  Site: 'Site web',
  'Sinhala ; Cingalais': 'Sinhala; Sinhalese',
  Bulgare: 'Bulgară',
  'Liste des commandes': 'Lista de comenzi',
  "L'utilisateur": 'Utilizatorul',
  Uzbek: 'Uzbecă',
  'Titre du texte': 'Titlul textului',
  Postuler: 'Aplicați la',
  'Nb de textes par Silo': 'Nr. de texte pe siloz',
  'Nombre total de mots (existants et ajoutés)':
    'Numărul total de cuvinte (existente și adăugate)',
  'Max.': 'Max.',
  Beauté: 'Frumusețe',
  Humanitaire: 'Umanitar',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte': 'Căutare după autor sau text',
  'Balise meta-description': 'Etichetă de descriere meta',
  Malgache: 'Malagasy',
  Tagalog: 'Tagalog',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Acest câmp trebuie să fie mai mare decât 0 și mai mic decât 1',
  'Le rédacteur': 'scriitorul',
  'a été mis à jour avec succès': 'a fost actualizată cu succes',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Trebuie să aibă cel puțin 8 caractere, cu majuscule, cifre, simboluri și să nu fie identic cu numele.',
  'textes en plus': 'mai multe texte',
  'Format souhaité': 'Formatul dorit',
  Chamorro: 'Chamorro',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': 'Detaliu privind linia de comandă',
  'Veuillez préciser la date': 'Vă rugăm să specificați data',
  Pali: 'Pali',
  Clients: 'Clienți',
  'Total facturé': 'Total facturat',
  'contenu validé par le client': 'conținut validat de către client',
  'Occitan (post 1500)': 'occitană (după 1500)',
  Japonais: 'Japoneză',
  Avaric: 'Avaric',
  'Voulez-vous vraiment activer': 'Chiar vrei să activezi',
  Sanskrit: 'Sanscrită',
  'Toutes les notifications': 'Toate notificările',
  Marathi: 'Marathi',
  Persan: 'Persană',
  'La commande pour le client': 'Comanda pentru client',
  'Intitulé de la commande': 'Titlul comenzii',
  Samoan: 'Samoan',
  Autres: 'Altele',
  Sport: 'Sport',
  "il y'a des commandes liés au site web ":
    'există comenzi legate de site-ul web',
  'Ajout de mots-clé avec la fréquence restante':
    'Adăugarea cuvintelor cheie cu frecvență restantă',
  'a été supprimé avec succès': 'a fost eliminat cu succes',
  Postulé: 'Postat',
  Literie: 'Lenjerie de pat',
  'Édité par le client': 'Editat de client',
  Cornouailles: 'Cornwall',
  Tigrinya: 'Tigrinya',
  Textes: 'Texte',
  'Soumettre un nouveau texte': 'Trimiteți un text nou',
  Téléphone: 'Telefon',
  Deadline: 'Termen limită',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    'S-a produs o eroare. Vă rugăm să verificați intrarea sau să încercați din nou mai târziu.',
  Marshallais: 'Marshall',
  "La demande d'ajout de crédit est envoyée":
    'Cererea de adăugare a creditului este trimisă',
  'Le rédacteur ': 'scriitorul',
  'Demander des crédits': 'Solicitarea unui credit',
  "Cette commande n'existe pas": 'Această comandă nu există',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Tahitian',
  Admins: 'Administratori',
  'Mettez des mots-clés avec fréquence': 'Setați cuvinte cheie cu frecvență',
  Notifications: 'Notificări',
  'en retard': 'târziu',
  "l'admin": 'administratorul',
  'Mail rédacteurs': 'scriitori de corespondență',
  Amharique: 'Amharic',
  Societe: 'Compania',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Numărul de texte adăugate',
  Société: 'Compania',
  Yoruba: 'Yoruba',
  'Le texte a été livré avec succès': 'Textul a fost livrat cu succes',
  'Mettez votre texte': 'Puneți textul dvs.',
  "Oups....! Une erreur s'est produite": 'Oops....! A apărut o eroare',
  'Prénom/Nom': 'Prenume/Nume de familie',
  'Max doit être sup à Min': 'Max trebuie să fie mai mare decât Min',
  'Importer un autre document': 'Importați un alt document',
  'Votre demande a expirée .. veuillez refaire une autre':
    'Cererea dvs. a expirat ... vă rugăm să faceți alta',
  'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Vă rugăm să corectați formatul golului semantic pentru a salva textul ca proiect',
  Exemple: 'Exemplu',
  Slovaque: 'Slovacia',
  Finance: 'Finanțe',
  Réactiver: 'Reactivați',
  'Nb textes': 'Nr. de texte',
  'Intitulé commande': 'Titlul comenzii',
  Supprimer: 'Ștergeți',
  Brouillon: 'Proiect',
  'Mots clés restants': 'Cuvinte cheie rămase',
  Date: 'Data',
  Rôle: 'Rol',
  'Ce champs doit être sup à 0': 'Acest câmp trebuie să fie mai mare decât 0',
  'Maison/Déco': 'Casă/Decor',
  'Vous avez': 'Ai',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Această linie de comandă nu poate fi ștearsă, deoarece o parte din text a fost transmisă',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'verifică-ți e-mailul și dă click pe link pentru a continua!',
  Sarde: 'Sardinia',
  'Note rédacteur': 'Nota scriitori',
  'Uighur ; Uyghur': 'Uighur; Uyghur',
  Name: 'Nume',
  'Commandes intelligentes': 'Comenzi smart',
  'Commandes avec révision': 'Comenzi smart + review',
  'Type de contenu': 'Tipul conținutului',
  'Fiche produit': 'Fișa produsului',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Scriere premium',
  'Rédaction avec révision': 'Scriere smart + review',
  'Rédaction intelligente': 'Scriere smarts',
  PREMIUM: 'PREMIUM',
  Type: 'Tip',
  Editer: 'Editați',
  'Tous les types': 'Toate tipurile',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Factures traitées': 'Facturi procesate',
  'Factures à valider': 'Facturi de validat',
  'Impossible de générer la facture ! Veuillez réessayer':
    'Imposibil de generat factura! Vă rugăm să încercați din nou',
  'Récapitulatif de facturation': 'Rezumatul facturării',
  'Demander une modification': 'Solicitați o modificare',
  'Valider et envoyer la facture': 'Validați și trimiteți factura',
  'Demande de modification': 'Cerere de modificare',
  'La facture a été validé avec succès!': 'Factura a fost validată cu succes!',
  "Oups un erreur c'est produit!": 'Ups, a apărut o eroare!',
  'Nombre de texte': 'Număr de texte',
  'Nombre de mot par texte :': 'Număr de cuvinte pe text:',
  Paiement: 'Plată',
  Virement: 'Transfer bancar',
  Délai: 'Termen',
  'Référence de votre facture': 'Referința facturii dumneavoastră',
  'Coût au mot': 'Cost pe cuvânt',
  'Montant global de la commande': 'Valoare totală a comenzii',
  'Votre facture': 'Factura dumneavoastră',
  'Importer votre facture': 'Importați factura dumneavoastră',
  'Pas de fichier disponible': 'Nu există fișier disponibil',
  Télécharger: 'Descărcați',
  Relancer: 'Relansați',
  'Générer la facture': 'Generați factura',
  Payée: 'Plătită',
  'En cours de paiement': 'În curs de plată',
  'En cours de validation': 'În curs de validare',
  'A envoyer': 'De trimis',
  'Modification demandée': 'Modificare solicitată',
  'Référence de facture est obligatoire': 'Referința facturii este obligatorie',
  'Le coût au mot doit être un nombre':
    'Costul pe cuvânt trebuie să fie un număr',
  'Le coût au mot doit être un nombre positif':
    'Costul pe cuvânt trebuie să fie un număr pozitiv',
  'Le coût au mot est obligatoire': 'Costul pe cuvânt este obligatoriu',
  'Le montant global de la commande doit être un nombre':
    'Valoarea totală a comenzii trebuie să fie un număr',
  'Le montant global de la commande doit être un nombre positif':
    'Valoarea totală a comenzii trebuie să fie un număr pozitiv',
  'Le montant global de la commande est obligatoire':
    'Valoarea totală a comenzii este obligatorie',
  'Bill reference existe déja , veuillez réessayer':
    'Referința facturii există deja, vă rugăm să încercați din nou',
  'La facture a été envoyée avec succès!': 'Factura a fost trimisă cu succes!',
  'Montant estimé': 'Suma estimată',
  Action: 'Acțiune',
  Facture: 'Factură',
  Statut: 'Stare',
  Factures: 'Facturi',
  'Valider la facture': 'Validează factura',
  'La relance a été envoyée avec succès !':
    'Reamintirea a fost trimisă cu succes!',
  'La demande de modification a été envoyée avec succès !':
    'Cererea de modificare a fost trimisă cu succes!',
  'Le cout est trop élevé': 'Costul este prea mare',
  'Le montant global de la commande est trop élevé ':
    'Valoarea totală a comenzii este prea mare',
  jours: 'zile',
  'Factures à envoyer': 'Facturi de trimis',
  'Valider le paiement': 'Validați plata',
  'Générer une facture': 'Generați o factură',
  'Montant rédacteur': 'Suma pentru redactor',
  'Montant estimé par Typix': 'Suma estimată de Typix',
  'Voir la modification': 'Vezi modificarea',
  'En cours de modification': 'În curs de modificare',
  'Mes Factures': 'Facturile mele',
  'En attente de validation': 'În așteptarea validării',
  'les valeurs négatives ne sont pas autorisées':
    'Valorile negative nu sunt permise.',
  'Texte Seulement': 'Doar text',
  'Traduction Seulement': 'Doar traducere',
  'La relance peut être faite 20 jours après la soumissions de la facture':
    'Memento-ul poate fi trimis în termen de 20 de zile de la trimiterea facturii',
  Instructions: 'Instrucțiuni',
  'Votre avis pour la commande': 'Opinia ta pentru comandă',
  'Votre évaluation est précieuse pour nous ! Merci !':
    'Evaluarea ta este valoroasă pentru noi! Mulțumim!',
  "Une erreur s'est produite lors de la soumission de votre évaluation. Veuillez réessayer ultérieurement!":
    'A apărut o eroare la trimiterea evaluării tale. Te rugăm să încerci din nou mai târziu!',
  Evaluer: 'Evaluare',
  Commentaire: 'Comentariu',
  'Votre commantaire': 'Comentariul tău',
  Note: 'Notă',
  'Votre retour est précieux pour nous et nous aide à améliorer nos services.':
    'Feedback-ul tău este valoros pentru noi și ne ajută să îmbunătățim serviciile noastre.',
  'Verifier la facture': 'Verificați factura',

  ' Votre retour est précieux pour nous et nous aide à améliorer nos services. Merci de partager votre évaluation avec nous!':
    'Feedback-ul dumneavoastră este valoros pentru noi și ne ajută să îmbunătățim serviciile noastre. Vă mulțumim că împărtășiți evaluarea dumneavoastră cu noi!',
  'Notes rédacteur': 'Notele redactorului',
  'Avis client': 'Opinii ale clienților',
  'Pas de commantaire': 'Niciun comentariu',
  "Qu'avez vous pensé de votre commande?":
    'Ce părere aveți despre comanda dumneavoastră?',
  'Evaluation de la commande': 'Evaluarea comenzii',
  'Rédiger un commentaire': 'Scrieți un comentariu',
  'Contribuez à nous permettre de vous proposer les services de rédaction de la plus haute qualité en partageant vos commentaires.':
    'Contribuiți la posibilitatea de a vă oferi servicii de redactare de cea mai înaltă calitate, împărtășindu-vă comentariile.',
  'Que pouvons-nous améliorer?': 'Ce putem îmbunătăți?',
  Continuer: 'Continuați',
  'Le dernier texte a été validé avec succès':
    'Ultimul text a fost validat cu succes',
  'Rechercher par rédacteur, commandes, et montant':
    'Căutați după redactor, comenzi și sumă',
  obligatoire: 'obligatoriu',
  'Note admin': 'Notă de la administrator',
  'Note client': 'Notă de la client',
  Partie: 'Parte',
  'Vous venez de valider tous les contenus rédigés par ':
    'Ați validat tocmai tot conținutul scris de ',
  "Qu'en avez vous pensé": 'Ce părere aveți',
  'La facture a été générée avec succès !':
    'Factura a fost generată cu succes!',
  'La taille du titre et/ou la méta-description est trop courte':
    'Lungimea titlului și/sau a descrierii meta este prea scurtă',

  'Demande de révision globale': 'Necesită o revizuire globală',
  Valider: 'Valida',
  'Obligatoire en cas d’optimisation ou traduction':
    'Obligatoriu în cazul optimizării sau traducerii',
  'Mots clés Bourrées': 'Keyword stuffing',
  'Votre commentaire...': 'Comentariul dvs...',
  'Ex : "Contenus de très bonne qualité':
    'Exemplu: "Conținut de foarte bună calitate"',
  "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?":
    'Ați putea, vă rog, să repartizați acest redactor pentru viitoarele comenzi?',
  'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing':
    'Acest buton este pentru a informa clientul că textul original conținea deja umplutură de cuvinte cheie',
  'Maillage Interne': 'Internal link',
  'Modifier le texte': 'Editează textul',
  Ajouter: 'Adaugă',
  'Demande examen': 'Cerere examen',
  'Recherche de mots clés à haute densité':
    'Cercetare de umplutură cu cuvinte cheie',
  'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ':
    'Ops, se pare că există keyword stuffing în textul dvs.: ',
  'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.':
    'Ops, se pare că există keyword stuffing (prea multe apariții ale unuia sau mai multor cuvinte cheie) în textul original.',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).':
    'Vă rugăm să vă asigurați că densitatea cuvintelor cheie este optimizată pentru SEO (o maxim de o apariție la fiecare 50 de cuvinte).',
  'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.':
    'Pentru asta, vă rugăm să nu editați textul existent și să trimiteți amabil o cerere de examinare către suport.',
  'Votre demande a été bien envoyée !':
    'Cererea dvs. a fost trimisă cu succes!',
  "Il y a eu un problème lors de l'envoi de votre demande":
    'A apărut o problemă la trimiterea cererii dvs.',
  'Veuillez attendez pour le  keyword Stuffing analyse':
    'Vă rugăm să așteptați pentru analiza keyword stuffing.',
  'Inserez le maillage interne': 'Introduceți rețeaua internă',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.':
    'Vă rugăm să vă asigurați că densitatea cuvintelor cheie este optimizată pentru SEO (maximum o apariție la fiecare 50 de cuvinte).\nDacă întâlniți un termen care poate avea mai multe sensuri (Exemplu: Former / Formation / Form sau Matelas / Matelassé), puteți solicita o revizuire de la suport.',
  'Mots clés à haute densité dans le texte actuel':
    'Palavras-chave de alta densidade no texto atual',
  'Mots clés à haute densité dans le texte initial':
    'Palavras-chave de alta densidade no texto inicial',
  'Texte en cours de rédaction !': 'Text în curs de redactare !',
  'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.':
    'Sunteți sigur(ă) că doriți să confirmați textul? Asigurați-vă mai întâi că ați salvat modificările.',
  "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande.":
    'Informație de ultimă oră! Soldul tău de credite va fi epuizat în curând :( Pentru a anticipa, nu ezita să soliciți acum mai multe credite pentru a-ți finaliza comanda.',
  'Voulez-vous confirmer l’URL actuelle ou la modifier ?':
    'Doriți să confirmați URL-ul actual sau să-l modificați?',
  'Veuillez fournir une URL valide':
    'Vă rugăm să furnizați o adresă URL validă.',
  'Merci de bien vouloir indiquer ci-dessous l’url de la page où a été intégré le contenu:':
    'Vă rugăm să indicați mai jos adresa URL a paginii unde a fost integrat conținutul:',
  'Contenu en ligne': 'Conținut online',
  'Télécharger le document de révision': 'Descărcați documentul de revizuire',
  'Date d’envoi': 'Data trimiterii',
  Information: 'Informație',
  Enregistrer: 'Salvare',
  'Textes en ligne': 'Texte online',
  'Livrer les textes': 'Livrați textele',
  Indisponible: 'Indisponibil',
  Disponible: 'Disponibil',
  'Les textes ont été livrés avec succès.':
    'Textele au fost livrate cu succes.',
  'Le texte a été livré avec succès.': 'Textul a fost livrat cu succes.',
  'Les textes ont été validés avec succès.':
    'Textele au fost validate cu succes.',
  'Le texte a été validé avec succès.': 'Textul a fost validat cu succes.',
  'Voulez-vous vraiment valider les Textes de la commande':
    'Doriți cu adevărat să validați textele comenzii?',
  'Voulez-vous vraiment livrer les Textes de la commande':
    'Doriți cu adevărat să livrați textele comenzii?',
  'Fiche produit longue': 'Fișă de produs lungă',
  'Fiche produit courte': 'Fișă de produs scurtă',
  'Un texte est trop long et ne peut pas être exporté sur Excel':
    'Un text este prea lung și nu poate fi exportat în Excel.',
  'Mettre un texte en brouillon en livré':
    'Schimbați un text de la schiță la livrat',
  'Remettre un texte validé à livré': 'Schimbați un text validat la livrat',
  'Invalider le texte': 'Invalidați textul',
  'Je souhaiterai modifier mon mot de passe : comment faire ?':
    'Aș dori să-mi schimb parola: cum fac asta?',
  'Comment mettre un contenu en bon pour livraison ?':
    'Cum să setați conținutul gata pentru livrare?',
  'Je ne peux pas mettre mon contenu en « brouillon »':
    'Nu pot salva conținutul meu ca "schiță"',
  'Typix n’a pas enregistré mes dernières modifications':
    'Typix nu a salvat ultimele mele modificări',
  'Comment rédiger une création de contenu ?':
    'Cum să scrii crearea de conținut?',
  'Comment rédiger une optimisation de contenu ?':
    'Cum să scrii optimizarea conținutului?',
  'Je ne vois plus ma commande – que faire ?':
    'Nu mai văd comanda mea – ce să fac?',
  'Comment générer une facture ?': 'Cum să generezi o factură?',
  'Je ne peux pas generer ma facture : que faire ?':
    'Nu pot genera factura mea: ce să fac?',
  'Tuto process Typix global': 'Proces global Typix - tutorial',
  'Tuto mise en ligne des contenus': 'Cum să încarci conținut - tutorial',
  'Modification mot de passe': 'Modificare parolă',
  'Le test ne me convient pas': 'Testul nu mi se potrivește',
  'Comment valider un contenu dans Typix ?':
    'Cum să validezi conținutul în Typix?',
  'Tuto Facturation': 'Tutorial de facturare',
  'Le statut du contenu a bien été modifié':
    'Statutul conținutului a fost modificat cu succes',
};
