export const frenchTranslation = {
  'Mon profil': 'Mon profil',
  Déconnexion: 'Déconnexion',
  'Tableau de bord': 'Tableau de bord',
  'Commandes non attribuées': 'Commandes non attribuées',
  'Commandes en cours': 'Commandes en cours',
  'Commandes en retard': 'Commandes en retard',
  'Commandes validées': 'Commandes validées',
  'Non attribuées': 'Non attribuées',
  'En cours': 'En cours',
  'En retard': 'En retard',
  Validées: 'Validées',
  Rechercher: 'Rechercher',
  'Mes Projets': 'Mes Projets',
  'Titre de la commande': 'Titre de la commande',
  'Textes livrés': 'Textes livrés',
  'Textes validés': 'Textes validés',
  'Nouveau texte': 'Nouveau texte',
  'Nom du texte': 'Nom du texte',
  'Mots clés': 'Mots clés',
  URL: 'URL',
  'Balise titre': 'Balise titre',
  'Balise meta-description': 'Balise meta-description',
  'Détails du texte': 'Détails du texte',
  Étape: 'Étape',
  '(Titre, mot clé, url, balise, méta-description)':
    '(Titre, mot clé, url, balise, méta-description)',
  'ajouter un texte': 'ajouter un texte',
  'Séparer les mots clés par un "enter"':
    'Séparer les mots clés par un "enter"',
  'mot-clé': 'mot-clé',
  'Télécharger le document': 'Télécharger le document',
  'Mots clés manquants': 'Mots clés manquants',
  'Ajout de mots-clé avec la fréquence restante':
    'Ajout de mots-clé avec la fréquence restante',
  'Résultat sémantique': 'Résultat sémantique',
  'Enrichissement sémantique': 'Enrichissement sémantique',
  'Insérez votre gap sémantique avec l’occurrence':
    'Insérez votre gap sémantique avec l’occurence',
  'Coller le texte initial dans le champs ci-dessous':
    'Coller le texte initial dans le champs ci-dessous',
  optionel: 'optionel',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Ajouter ou compléter ci-dessous votre contenu',
  'Analyses restantes': 'Analyses restantes',
  'Nombre total de mots (existants et ajoutés)':
    'Nombre total de mots (existants et ajoutés)',
  'Nombre de mot à ajouter': 'Nombre de mot à ajouter',
  'Nombre total de mots ajoutés': 'Nombre total de mots ajoutés',
  Langue: 'Langue',
  Langues: 'Langues',
  Disponibilité: 'Disponibilité',
  'ID rédacteur': 'ID rédacteur',
  'Enregistrer et informer client': 'Enregistrer et informer client',
  'Soumettre un nouveau texte': 'Soumettre un nouveau texte',
  Sauvegarder: 'Sauvegarder',
  'Bon pour livraison': 'Bon pour livraison',
  Comparer: 'Comparer',
  Analyser: 'Analyser',
  Brouillon: 'Brouillon',
  'Il semblerait que tu n’aies plus de crédit d’analyse':
    'Il semblerait que tu n’aies plus de crédit d’analyse',
  'Ce champ est obligatoire': 'Ce champ est obligatoire',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Êtes vous sur de vouloir sauvegarder le texte',
  'Le texte a été enregistré avec succès':
    'Le texte a été enregistré avec succès',
  'La traduction a été enregistrée avec succès':
    'La traduction a été enregistrée avec succès',
  'Texte + Traduction enregistré avec succès':
    'Texte + Traduction enregistré avec succès',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Veuillez changer les informations suivantes: titre et/ou mot clé',
  "Une erreur s'est produite": "Une erreur s'est produite",
  'Veuillez corriger le format de  votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Veuillez ajouter un texte initial afin de pouvoir sauvegarder le texte en brouillon',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon',
  'Le texte': 'Le texte',
  'a été mis en brouillon': 'a été mis en brouillon',
  'Le texte a été mis en brouillon': 'Le texte a été mis en brouillon',
  'Le texte a été livré avec succès': 'Le texte a été livré avec succès',
  'Modification effectuée avec succès': 'Modification effectuée avec succès',
  "La demande d'ajout de crédit est envoyée":
    "La demande d'ajout de crédit est envoyée",
  'La commande a été supprimé avec succès':
    'La commande a été supprimé avec succès',
  'Voulez-vous vraiment supprimer la commande de':
    'Voulez-vous vraiment supprimer la commande de',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Voulez-vous vraiment supprimer la ligne de commande pour',
  'Contrat déjà existant, veuillez modifier les informations':
    'Contrat déjà existant, veuillez modifier les informations',
  Postuler: 'Postuler',
  Postulé: 'Postulé',
  'Typix - Mon profil': 'Typix - Mon profil',
  'Votre profil a été mis à jour avec succès.':
    'Votre profil a été mis à jour avec succès.',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.",
  'Ce champs est obligatoire': 'Ce champs est obligatoire',
  Prénom: 'Prénom',
  'E-mail': 'E-mail',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Ce champs est obligatoire et ne peut avoir que des nombres',
  'Mot de passe': 'Mot de passe',
  'doit comprendre au moins 8 charactères':
    'doit comprendre au moins 8 charactères',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre',
  'ne doit pas inclure votre nom/prénom':
    'ne doit pas inclure votre nom/prénom',
  'Confirmer le mot de passe': 'Confirmer le mot de passe',
  'Les deux mots de passe ne sonts pas identiques':
    'Les deux mots de passe ne sonts pas identiques',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.',
  'Typix - Nouvelle commande': 'Typix - Nouvelle commande',
  'La commande pour le client': 'La commande pour le client',
  'a été ajouté avec succès': 'a été ajouté avec succès',
  'Le titre de la commande existe déjà': 'Le titre de la commande existe déjà',
  "Oups....! Une erreur s'est produite": "Oups....! Une erreur s'est produite",
  'Nouvelle commande': 'Nouvelle commande',
  'Réf devis': 'Réf devis',
  'Tâche - id tâche': 'Tâche - id tâche',
  'Intitulé de la commande': 'Intitulé de la commande',
  'URL du site': 'URL du site',
  'Nombre de textes': 'Nombre de textes',
  'Tarif au mot': 'Tarif au mot',
  Consultant: 'Consultant',
  'Contact edito': 'Contact edito',
  'Coût d’achat max/mot': 'Coût d’achat max/mot',
  'Rédacteur attribué': 'Rédacteur attribué',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Ce champs doit être sup à 0 et inférieur à 1',
  'Ce champs doit être sup à 0': 'Ce champs doit être sup à 0',
  'Nb textes': 'Nb textes',
  Min: 'Min',
  'Max doit être sup à Min': 'Max doit être sup à Min',
  Max: 'Max',
  'Nombre de mots par texte': 'Nombre de mots par texte',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    "Ce champs doit être sup à 0 car facturation n'est pas au réel",
  'Facturation au réel': 'Facturation au réel',
  'Brief détaillé': 'Brief détaillé',
  'Gap sémantique': 'Gap sémantique',
  Documentation: 'Documentation',
  'Champs obligatoires': 'Champs obligatoires',
  'à cocher si le nombre de mots est différent par texte':
    'à cocher si le nombre de mots est différent par texte',
  'Lien drive...': 'Lien drive...',
  'Le contrat pour le client': 'Le contrat pour le client',
  'a été créé avec succès': 'a été créé avec succès',
  'Nouveau client': 'Nouveau client',
  'Prénom / Nom du client': 'Prénom / Nom du client',
  'Fiche du site web ': 'Fiche du site web ',
  'Compte client': 'Compte client',
  'Url du site': 'Url du site',
  'Nb de textes par Silo': 'Nb de textes par Silo',
  'Nombre de FAQ': 'Nombre de FAQ',
  'Nombre de GMB': 'Nombre de GMB',
  'Nombre de Silo': 'Nombre de Silo',
  'Nb de textes à optimiser': 'Nb de textes à optimiser',
  'Thématiques d’expertise': 'Thématiques d’expertise',
  'Typix - Créer rédacteur': 'Typix - Créer rédacteur',
  'Le rédacteur ': 'Le rédacteur ',
  'Fiche du rédacteur': 'Fiche du rédacteur',
  'Ajouter un rédacteur': 'Ajouter un rédacteur',
  Mail: 'Mail',
  Adresse: 'Adresse',
  Société: 'Société',
  'Analyse/Limite': 'Analyse/Limite',
  'Prénom/Nom': 'Prénom/Nom',
  Téléphone: 'Téléphone',
  Thématiques: 'Thématiques',
  'Note rédacteur': 'Note rédacteur',
  Global: 'Global',
  Deadline: 'Deadline',
  Rédacteurs: 'Rédacteurs',
  'Afficher les résultats': 'Afficher les résultats',
  Afficher: 'Afficher',
  '0 rédacteurs': '0 rédacteurs',
  'Typix - Mon tableau de bord': 'Typix - Mon tableau de bord',
  'Le contrat de': 'Le contrat de',
  'a été supprimé avec succès': ' a été supprimé avec succès',
  "il y'a des commandes liés au site web ":
    "il y'a des commandes liés au site web ",
  'Liste des clients': 'Liste des clients',
  Nom: 'Nom',
  'Nom client': 'Nom',
  Commandes: 'Commandes',
  Éditer: 'Éditer',
  Supprimer: 'Supprimer',
  'non attribuées': 'non attribuées',
  'en cours': 'en cours',
  'en retard': 'en retard',
  validées: 'validées',
  'Voulez-vous vraiment supprimer le contrat de':
    'Voulez-vous vraiment supprimer le contrat de',
  'Temps restant': 'Temps restant',
  'Nb de textes': 'Nb de textes',
  'Nb total de textes': 'Nb total de textes',
  'Nb de mots': 'Nb de mots',
  'Au réel': 'Au réel',
  'Nb mots/texte': 'Nb mots/texte',
  Prix: 'Prix',
  Textes: 'Textes',
  Validation: 'Validation',
  'Votre décision a été prise en compte avec succès':
    'Votre décision a été prise en compte avec succès',
  'Détail de la commande': 'Détail de la commande',
  'Détail ligne de commande': 'Détail ligne de commande',
  Historique: 'Historique',
  'Typix - Détails de la commande': 'Typix - Détails de la commande',
  'Contenu disponible': 'Contenu disponible',
  Noir: 'Noir',
  Orange: 'Orange',
  Violet: 'Violet',
  Vert: 'Vert',
  'contenu en attente de vérification client':
    ' contenu en attente de vérification client',
  'contenu en attente de repasse rédacteur':
    'contenu en attente de repasse rédacteur',
  'contenu repassé, en attente validation client':
    'contenu repassé, en attente validation client',
  'contenu validé par le client': 'contenu validé par le client',
  'Tout télécharger': 'Tout télécharger',
  'Ajouter un texte': 'Ajouter un texte',
  Traduction: 'Traduction',
  'Tous les textes': 'Tous les textes',
  'Textes revisés': 'Textes revisés',
  'Révision en attente': 'Révision en attente',
  Brouillons: 'Brouillons',
  "Date d'ajout": "Date d'ajout",
  'Édité par le client': 'Édité par le client',
  'Révision demandée': 'Révision demandée',
  Validé: 'Validé',
  'résultats sur': 'résultats',
  Précédent: 'Précédent',
  Suivant: 'Suivant',
  Oui: 'Oui',
  Non: 'Non',
  'Intitulé commande': 'Intitulé commande',
  'Nombre de mots gérés': 'Nombre de mots gérés',
  'Min/Max': 'Min/Max',
  'Nombre de mots rédigés': 'Nombre de mots rédigés',
  'Status texte': 'Status texte',
  'Date de livraison': 'Date de livraison',
  Score: 'Score',
  'Date deadline validation client': 'Date deadline validation client',
  'Date de validation': 'Date de validation',
  'Liste des commandes': 'Liste des commandes',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune",
  'Typix - Détails du texte': 'Typix - Détails du texte',
  'Le texte initial de': 'Le texte initial de',
  'a été activé': 'a été activé',
  'a été livré avec succès': 'a été livré avec succès',
  'Mot clé': 'Mot clé',
  'Format souhaité': 'Format souhaité',
  'mot-clé (fréquence)': 'mot-clé (fréquence)',
  'Séparez les mots-clés par un Enter .':
    'Séparez les mots-clés par un " Enter ".',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n…':
    'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n…',
  'Ajout de mots-clés avec la fréquence restante':
    'Ajout de mots-clés avec la fréquence restante',
  'Mots clés restants': 'Mots clés restants',
  Réactiver: 'Réactiver',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Ajouter ou compléter ci-dessous votre contenu :',
  'Nombre total de mots (existants et ajoutés) :':
    'Nombre total de mots (existants et ajoutés) :',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Nombre de mot à ajouter (Min/Max) : ',
  'Nombre total de mots ajoutés :': 'Nombre total de mots ajoutés :',
  Rôle: 'Rôle',
  Site: 'Site',
  Modifier: 'Modifier',
  Désactiver: 'Désactiver',
  'Ajouter un admin': 'Ajouter un admin',
  'Ajouter un consultant': 'Ajouter un consultant',
  'Ajouter un client': 'Ajouter un client',
  'Tous les rôles': 'Tous les rôles',
  Admins: 'Admins',
  Clients: 'Clients',
  Consultants: 'Consultants',
  'Typix - Gestion des utilisateurs': 'Typix - Gestion des utilisateurs',
  'Gestion des utilisateurs': 'Gestion des utilisateurs',
  'Rechercher par mail, prénom, nom, site...':
    'Rechercher par mail, prénom, nom, site...',
  'Typix - Fiche site web': 'Typix - Fiche site web',
  "il y'a des commandes liés à au site web":
    "il y'a des commandes liés à au site web",
  'Fiche du site web': 'Fiche du site web',
  'Prénom/Nom du client': 'Prénom/Nom du client',
  'Mail client': 'Mail client',
  'Détail de la prestation du contenu': 'Détail de la prestation du contenu',
  'Voir les commandes': 'Voir les commandes',
  'Typix - Fiche rédacteur': 'Typix - Fiche rédacteur',
  'Vous ne pouvez pas supprimer le rédacteur':
    'Vous ne pouvez pas supprimer le rédacteur',
  'car celui-ci a déjà rédigé des textes':
    'car celui-ci a déjà rédigé des textes',
  'Voulez-vous vraiment supprimer le rédacteur':
    'Voulez-vous vraiment supprimer le rédacteur',
  disponible: 'disponible',
  indisponible: 'indisponible',
  'Exporter .csv': 'Exporter .csv',
  'listeRedacteur-': 'listeRedacteur-',
  Notifications: 'Notifications',
  'nouvelles notifications': 'nouvelles notifications',
  'Vous avez': 'Vous avez',
  'textes en plus': 'additional text(s)',
  'textes livrés en attente de validation':
    'textes livrés en attente de validation',
  'révisions effectuées en attentes de validation':
    'révisions effectuées en attentes de validation',
  'commandes prête': 'commandes prête',
  'Toutes les notifications': 'Toutes les notifications',
  'Révisions effectués': 'Révisions effectués',
  'Typix - Mon Profile': 'Typix - Mon Profile',
  'Le texte a été validé avec succès': 'Le texte a été validé avec succès',
  'Titre du texte': 'Titre du texte',
  'Séparez les mots-clés par un " Enter ".':
    'Séparez les mots-clés par un " Enter ".',
  'Valider le texte': 'Valider le texte',
  'Demander une révision': 'Demander une révision',
  'Demander une révision global': 'Demander une repasse globale',
  'Mes Commandes': 'Mes Commandes',
  rédacteurs: 'rédacteurs',
  'Export en cours de téléchargement…': 'Export en cours de téléchargement…',
  "Pas d'options": "Pas d'options",
  "Un utilisateur existe déjà avec l'email":
    "Un utilisateur existe déjà avec l'email",
  'Le consultant': 'Le consultant',
  'Le client': 'Le client',
  "l'admin": "l'admin",
  'le consultant': 'le consultant',
  'le client': 'le client',
  'le rédacteur': 'le rédacteur',
  'La fiche du website': 'La fiche du website',
  'a été mis à jour avec succès': 'a été mis à jour avec succès',
  'Votre candidature a bien été envoyée':
    'Votre candidature a bien été envoyée',
  'Postuler à la commande': 'Postuler à la commande',
  'Livraison estimé au': 'Livraison estimé au',
  Annuler: 'Annuler',
  Confirmer: 'Confirmer',
  'Vous êtes sûr': 'Vous êtes sûr(e)',
  'Demander des crédits': 'Demander des crédits',
  'Félicitations !': 'Félicitations !',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'vérifiez votre email et cliquez sur le lien pour continuer !',
  "cet email n'existe pas": "cet email n'existe pas",
  'Réinitialiser votre mot de passe': 'Réinitialiser votre mot de passe',
  Réinitialiser: 'Réinitialiser',
  Envoyer: 'Envoyer',
  Ou: 'Ou',
  'Veuillez donner un descriptif au document téléchargé':
    'Veuillez donner un descriptif au document téléchargé',
  'Votre demande de révision a bien été envoyée.':
    'Votre demande de révision a bien été envoyée.',
  'Veuillez saisir un texte ou importer un fichier.':
    'Veuillez saisir un texte ou importer un fichier.',
  'Demande de révision': 'Demande de révision',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses",
  'Dans le cadre d’une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'Dans le cadre d’une repasse sur des contenus, seuls les éléments suivants seront pris en charge',
  'Si des phrases ne sont pas dans un français correct':
    'Si des phrases ne sont pas dans un français correct',
  'importer un document': 'importer un document',
  'le contenu présente des inexactitudes':
    'le contenu présente des inexactitudes',
  'Si le contenu ne respecte pas les règles du brief':
    'Si le contenu ne respecte pas les règles du brief',
  'Votre text ne de doit pas dépasser 500 mots':
    'Votre text ne de doit pas dépasser 500 mots',
  'Les demandes suivantes ne seront pas prises en compte':
    'Les demandes suivantes ne seront pas prises en compte',
  'Coquilles (double espace, « s »…)': 'Coquilles (double espace, « s »…)',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Expressions ou phrases françaises mais ne vous convenant pas',
  'Toute demande n’ayant pas été précisée lors du brief':
    'Toute demande n’ayant pas été précisée lors du brief',
  'Pour ajouter un nouveau texte': 'Pour ajouter un nouveau texte',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    "Pour ajouter un texte (optimisation d'un contenu existant)",
  'Nombre de Jours': 'Nombre de Jours',
  'délai dépassé': 'délai dépassé',
  'Livraison estimée': 'Livraison estimée',
  'Modifier l’admin': 'Modifier l’admin',
  'Modifier le client': 'Modifier le client',
  'Modifier le rédacteur': 'Modifier le rédacteur',
  'Modifier le consultant': 'Modifier le consultant',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Oups...! il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.',
  'Voulez-vous vraiment supprimer': 'Voulez-vous vraiment supprimer',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer",
  'ne peut pas être supprimé car il est lié à des sites web':
    'ne peut pas être supprimé car il est lié à des sites web',
  'ne peut pas être supprimé pour le moment':
    'ne peut pas être supprimé pour le moment',
  "L'utilisateur": "L'utilisateur",
  'Voulez-vous vraiment désactiver': 'Voulez-vous vraiment désactiver',
  'Voulez-vous vraiment activer': 'Voulez-vous vraiment activer',
  'a été désactivé avec succès': 'a été désactivé avec succès',
  'a été activé avec succès': 'a été activé avec succès',
  Utilisateurs: 'Utilisateurs',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande",
  'La commande a été mis à jour avec succès !':
    'La commande a été mis à jour avec succès !',
  "Cette commande n'existe pas": "Cette commande n'existe pas",
  'Mail rédacteurs': 'Mail rédacteurs',
  'Chef edito': 'Chef edito',
  'Min.': 'Min.',
  'Max.': 'Max.',
  Consignes: 'Consignes',
  Rédaction: 'Rédaction',
  'La ligne de commande pour': 'La ligne de commande pour',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré',
  "Une erreur s'est produite, veuillez réessayer":
    "Une erreur s'est produite, veuillez réessayer",
  'Les lignes de commandes ont été mises à jour avec succès !':
    'Les lignes de commandes ont été mises à jour avec succès !',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Mise à jour non autorisée car des textes ont été livrés',
  'Ce champs doit être sup ou égale nombre de texte':
    'Ce champs doit être sup ou égale nombre de texte',
  'livré et inférieur au nombre de texte non affecté':
    'livré et inférieur au nombre de texte non affecté',
  'Nombre de textes restants': 'Nombre de textes restants',
  'Nombre de textes ajoutés': 'Nombre de textes ajoutés',
  voir: 'voir',
  'Le texte a été supprimé avec succès': 'Le texte a été supprimé avec succès',
  'Recherche par rédacteur ou texte': 'Recherche par rédacteur ou texte',
  'Mail envoyé': 'Mail envoyé',
  Date: 'Date',
  'Liste des rédacteurs': 'Liste des rédacteurs',
  'Chercher un rédacteur': 'Chercher un rédacteur',
  'rédacteurs trouvés': 'rédacteurs trouvés',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textes  de la commande':
    'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textes  de la commande',
  'Ce champ est obligatoire doit être supérieur à 0':
    'Ce champ est obligatoire doit être supérieur à 0',
  'Doit être inférieur au nombre maximum':
    'Doit être inférieur au nombre maximum',
  "La date doit être ultérieure à aujourd'hui.":
    "La date doit être ultérieure à aujourd'hui.",
  'Veuillez fournir une date valide': 'Veuillez fournir une date valide',
  'Veuillez préciser la date': 'Veuillez préciser la date',
  'email invalide': 'email invalide',
  'Numéro invalide': 'Numéro invalide',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial',
  'Ne doit pas contenir votre nom': 'Ne doit pas contenir votre nom',
  'Le mot de passe de confirmation ne correspond pas':
    'Le mot de passe de confirmation ne correspond pas',
  Compte: 'Compte',
  'Nb de silo par texte': 'Nb de silo par texte',
  'Nb de FAQ': 'Nb de FAQ',
  'Nb de GMB': 'Nb de GMB',
  'Nb de Silo': 'Nb de Silo',
  'Détail de la prestation': 'Détail de la prestation',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom',
  'Contenu rédigé': 'Contenu rédigé',
  'Ne plus afficher ce message': 'Ne plus afficher ce message',
  'Mail clients': 'Mail clients',
  'Notes rédacteurs': 'Notes rédacteurs',
  'Tarif/Mot': 'Tarif/Mot',
  'Min/Max mots': 'Min/Max mots',
  Livrés: 'Livrés',
  Validés: 'Validés',
  'Coût d’achat': 'Coût d’achat',
  'Total facturé': 'Total facturé',
  'Rechercher un rédacteur': 'Rechercher un rédacteur',
  'Nom/prénom': 'Nom/prénom',
  Contacter: 'Contacter',
  Notes: 'Notes',
  email: 'email',
  'mot de passe oublié': 'mot de passe oublié',
  'Veuillez saisir des champs valides': 'Veuillez saisir des champs valides',
  'Mot de passe incorrect': 'Mot de passe incorrect',
  "L'émail": "L'émail",
  "n'existe pas": "n'existe pas",
  'Réinitialiser  votre mot de passe': 'Réinitialiser  votre mot de passe',
  'Mot de passe mis à jour avec succès': 'Mot de passe mis à jour avec succès',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Ce mot de passe est le mot de passe que vous avez oublié',
  'Les données fournies sont invalides': 'Les données fournies sont invalides',
  'Votre demande a expirée .. veuillez refaire une autre':
    'Votre demande a expirée .. veuillez refaire une autre',
  'Confirmer le nouveau mot de passe': 'Confirmer le nouveau mot de passe',
  'Mettez des mots-clés avec fréquence': 'Mettez des mots-clés avec fréquence',
  'Mettez votre texte': 'Mettez votre texte',
  Autres: 'Autres',
  rédacteur: 'rédacteur',
  Différence: 'Différence',
  'Comparaison hors ligne': 'Comparaison hors ligne',
  Heures: 'Heures',
  Titre: 'Titre',
  'Nombre de mots': 'Nombre de mots',
  Texte: 'Texte',
  Résultats: 'Résultats',
  Beauté: 'Beauté',
  'Tourisme/Loisirs': 'Tourisme/Loisirs',
  Voyage: 'Voyage',
  'Immo/Defisc': 'Immo/Defisc',
  Automobile: 'Automobile',
  'Maison/Déco': 'Maison/Déco',
  Technologie: 'Technologie',
  Actualités: 'Actualités',
  Art: 'Art',
  Associations: 'Associations',
  'Banque/Assurance': 'Banque/Assurance',
  'Business/Management': 'Business/Management',
  Commerce: 'Commerce',
  Droit: 'Droit',
  Emploi: 'Emploi',
  'Energie/Environnement': 'Energie/Environnement',
  'Enfant/Puéri': 'Enfant/Puéri',
  Enseignement: 'Enseignement',
  Entreprise: 'Entreprise',
  Finance: 'Finance',
  'Grande distribution': 'Grande distribution',
  Humanitaire: 'Humanitaire',
  Institutions: 'Institutions',
  Internet: 'Internet',
  Jeux: 'Jeux',
  Literie: 'Literie',
  Mode: 'Mode',
  Musique: 'Musique',
  Outillage: 'Outillage',
  Perso: 'Perso',
  Polyvalent: 'Polyvalent',
  Regional: 'Regional',
  'Santé/Bien-être': 'Santé/Bien-être',
  Sciences: 'Sciences',
  Services: 'Services',
  Societe: 'Societe',
  Sortir: 'Sortir',
  Sport: 'Sport',
  Téléphonie: 'Téléphonie',
  Transport: 'Transport',
  'Travaux/BTP': 'Travaux/BTP',
  Webmaster: 'Webmaster',
  Cuisine: 'Cuisine',
  'Vos demandes': 'Vos demandes',
  'Importer un autre document': 'Importer un autre document',
  Exemple: 'Exemple',
  Français: 'Français',
  Anglais: 'Anglais',
  Espagnol: 'Espagnol',
  Italien: 'Italien',
  Allemand: 'Allemand',
  Afar: 'Afar',
  Abkhazien: 'Abkhazien',
  Avestan: 'Avestan',
  Afrikaans: 'Afrikaans',
  Akan: 'Akan',
  Amharique: 'Amharique',
  Aragonais: 'Aragonais',
  Arabe: 'Arabe',
  Assamais: 'Assamais',
  Avaric: 'Avaric',
  Aymara: 'Aymara',
  Azerbaïdjanais: 'Azerbaïdjanais',
  Bashkir: 'Bashkir',
  Biélorusse: 'Biélorusse',
  Bulgare: 'Bulgare',
  'Langues bihari': 'Langues bihari',
  Bislama: 'Bislama',
  Bambara: 'Bambara',
  Bengali: 'Bengali',
  Tibétain: 'Tibétain',
  Breton: 'Breton',
  Bosniaque: 'Bosniaque',
  'Catalan ; Valencien': 'Catalan ; Valencien',
  Tchétchène: 'Tchétchène',
  Chamorro: 'Chamorro',
  Corse: 'Corse',
  Cree: 'Cree',
  Czech: 'Czech',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique',
  Tchouvache: 'Tchouvache',
  Welsh: 'Welsh',
  Danois: 'Danois',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi ; Dhivehi ; Maldivien',
  Dzongkha: 'Dzongkha',
  Ewe: 'Ewe',
  'Grec moderne (1453-)': 'Grec moderne (1453-)',
  Grec: 'Grec',
  Estonien: 'Estonien',
  Basque: 'Basque',
  Persan: 'Persan',
  Fulah: 'Fulah',
  Finnois: 'Finnois',
  Fidjien: 'Fidjien',
  Féroïen: 'Féroïen',
  'Frison occidental': 'Frison occidental',
  Irlandais: 'Irlandais',
  Gaélique: 'Gaélique',
  Galicien: 'Galicien',
  Guarani: 'Guarani',
  Gujarati: 'Gujarati',
  Manx: 'Manx',
  Hausa: 'Hausa',
  Hébreu: 'Hébreu',
  Hindi: 'Hindi',
  'Hiri Motu': 'Hiri Motu',
  Croate: 'Croate',
  'Haïtien ; créole haïtien': 'Haïtien ; créole haïtien',
  Hongrois: 'Hongrois',
  Arménien: 'Arménien',
  Herero: 'Herero',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Association internationale des langues auxiliaires)',
  Indonésien: 'Indonésien',
  'Interlingue ; Occidental': 'Interlingue ; Occidental',
  Igbo: 'Igbo',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi (Nuosu)',
  Inupiaq: 'Inupiaq',
  Ido: 'Ido',
  Islandais: 'Islandais',
  Inuktitut: 'Inuktitut',
  Japonais: 'Japonais',
  Javanais: 'Javanais',
  Géorgien: 'Géorgien',
  Kongo: 'Kongo',
  'Kikuyu ; Gikuyu': 'Kikuyu (Gikuyu)',
  'Kuanyama ; Kwanyama': 'Kwanyama (Kuanyama)',
  Kazakh: 'Kazakh',
  'Kalaallisut ; Groenlandais': 'Kalaallisut ; Groenlandais',
  'Khmer central': 'Khmer (Central)',
  Kannada: 'Kannada',
  Coréen: 'Coréen',
  Kanuri: 'Kanuri',
  Kashmiri: 'Kashmiri',
  Kurde: 'Kurde',
  Komi: 'Komi',
  Cornouailles: 'Cornouailles',
  'Kirghiz ; Kirghiz': 'Kirghiz ; Kirghiz',
  Latin: 'Latin',
  'Luxembourgeois ; Letzeburgesch': 'Luxembourgeois ; Letzeburgesch',
  Ganda: 'Ganda',
  'Limburgan ; Limburger ; Limburgish': 'Limburgan ; Limburger ; Limburgish',
  Lingala: 'Lingala',
  Lao: 'Lao',
  Lituanien: 'Lituanien',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Letton',
  Malgache: 'Malgache',
  Marshallais: 'Marshallais',
  Maori: 'Maori',
  Macédonien: 'Macédonien',
  Malayalam: 'Malayalam',
  Mongolien: 'Mongolian',
  Marathi: 'Marathi',
  Malay: 'Malay',
  Maltais: 'Maltais',
  Birmane: 'Birmane',
  Nauru: 'Nauru',
  'Bokmål, norvégien ; Bokmål norvégien':
    'Bokmål, norvégien ; Bokmål norvégien',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, Nord ; Ndebele Nord',
  Népalais: 'Népalais',
  Ndonga: 'Ndonga',
  'Hollandais ; Flamand': 'Hollandais ; Flamand',
  Néerlandais: 'Néerlandais',
  'Nynorsk norvégien': 'Nynorsk norvégien',
  Norvégien: 'Norvégien',
  'Ndebele, South ; South Ndebele': 'Ndebele, South ; South Ndebele',
  'Navajo ; Navaho': 'Navajo',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa',
  'Occitan (post 1500)': 'Occitan',
  Ojibwa: 'Ojibwe',
  Oromo: 'Oromo',
  Oriya: 'Oriya',
  'Ossétien ; Ossétique': 'Ossetian',
  'Panjabi ; Punjabi': 'Punjabi',
  Pali: 'Pali',
  Polish: 'Polish',
  'Pushto ; Pashto': 'Pashto',
  Portugais: 'Portuguese',
  Quechua: 'Quechua',
  Romanche: 'Romansh',
  Rundi: 'Rundi',
  'Roumain ; Moldave ; Moldovan': 'Roumain ; Moldave ; Moldovan',
  Roumain: 'Roumain',
  Russe: 'Russe',
  Kinyarwanda: 'Kinyarwanda',
  Sanskrit: 'Sanskrit',
  Sarde: 'Sardinian',
  Sindhi: 'Sindhi',
  'Sami du Nord': 'Northern Sami',
  Sango: 'Sango',
  'Sinhala ; Cingalais': 'Sinhala',
  Slovaque: 'Slovak',
  Slovène: 'Slovenian',
  Samoan: 'Samoan',
  Shona: 'Shona',
  Somali: 'Somali',
  Albanais: 'Albanian',
  Serbe: 'Serbian',
  Swati: 'Swati',
  'Sotho, Southern': 'Southern Sotho',
  Sundanese: 'Sundanese',
  Suédois: 'Swedish',
  Swahili: 'Swahili',
  Tamil: 'Tamil',
  Telugu: 'Telugu',
  Tadjik: 'Tajik',
  Thai: 'Thai',
  Tigrinya: 'Tigrinya',
  Turkmène: 'Turkmen',
  Tagalog: 'Tagalog',
  Tswana: 'Tswana',
  'Tonga (Îles Tonga)': 'Tongan',
  Turc: 'Turkish',
  Tsonga: 'Tsonga',
  Tatar: 'Tatar',
  Twi: 'Twi',
  Tahitien: 'Tahitian',
  'Uighur ; Uyghur': 'Uighur',
  Ukrainien: 'Ukrainian',
  Urdu: 'Urdu',
  Uzbek: 'Uzbek',
  Venda: 'Venda',
  Vietnamien: 'Vietnamese',
  Volapük: 'Volapük',
  Wallon: 'Walloon',
  Wolof: 'Wolof',
  Xhosa: 'Xhosa',
  Yiddish: 'Yiddish',
  Yoruba: 'Yoruba',
  'Zhuang ; Chuang': 'Zhuang ; Chuang',
  Chinois: 'Chinois',
  Zoulou: 'Zoulou',
  Name: 'Nom',
  'Commandes intelligentes': 'Commandes smart',
  'Commandes avec révision': 'Commandes smart + review',
  'Type de contenu': 'Type de contenu',
  'Fiche produit': 'Fiche produit',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Rédaction premium',
  'Rédaction avec révision': 'Rédaction smart + review',
  'Rédaction intelligente': 'Rédaction smart',
  PREMIUM: 'PREMIUM',
  Type: 'Type',
  Editer: 'Editer',
  'Tous les types': 'Tous les types',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Factures traitées': 'Factures traitées',

  'Factures à valider': 'Factures à valider',
  'Impossible de générer la facture ! Veuillez réessayer':
    'Impossible de générer la facture ! Veuillez réessayer',
  'Récapitulatif de facturation': 'Récapitulatif de facturation',
  'Demander une modification': 'Demander une modification',
  'Valider et envoyer la facture': 'Valider et envoyer la facture',
  'Demande de modification': 'Demande de modification',
  'La facture a été validé avec succès!':
    'La facture a été validé avec succès!',
  "Oups un erreur c'est produit!": "Oups un erreur c'est produit!",
  'Nombre de texte': 'Nombre de texte',
  'Nombre de mot par texte :': 'Nombre de mot par texte :',
  Paiement: 'Paiement',
  Virement: 'Virement',
  Délai: 'Délai',
  'Référence de votre facture': 'Référence de votre facture',
  'Coût au mot': 'Coût au mot',
  'Montant global de la commande': 'Montant global de la commande',
  'Votre facture': 'Votre facture',
  'Importer votre facture': 'Importer votre facture',
  'Pas de fichier disponible': 'Pas de fichier disponible',
  Télécharger: 'Télécharger',
  Relancer: 'Relancer',
  'Générer la facture': 'Générer la facture',

  Payée: 'Payée',
  'En cours de paiement': 'En cours de paiement',
  'En cours de validation': 'En cours de validation',
  'En cours de modification': 'En cours de modification',
  'A envoyer': 'A envoyer',
  'Modification demandée': 'Modification demandée',
  'Référence de facture est obligatoire':
    'Référence de facture est obligatoire',
  'Le coût au mot doit être un nombre': 'Le coût au mot doit être un nombre',
  'Le coût au mot doit être un nombre positif':
    'Le coût au mot doit être un nombre positif',
  'Le coût au mot est obligatoire': 'Le coût au mot est obligatoire',
  'Le montant global de la commande doit être un nombre':
    'Le montant global de la commande doit être un nombre',
  'Le montant global de la commande doit être un nombre positif':
    'Le montant global de la commande doit être un nombre positif',
  'Le montant global de la commande est obligatoire':
    'Le montant global de la commande est obligatoire',
  'Bill reference existe déja , veuillez réessayer':
    'Bill reference existe déja , veuillez réessayer',
  'La facture a été envoyée avec succès!':
    'La facture a été envoyée avec succès!',
  'Montant estimé': 'Montant estimé',
  Action: 'Action',
  Facture: 'Facture',
  Factures: 'Factures',
  Statut: 'Statut',
  'Valider la facture': 'Valider la facture',
  'La relance a été envoyée avec succès !':
    'La relance a été envoyée avec succès !',
  'La demande de modification a été envoyée avec succès !':
    'La demande de modification a été envoyée avec succès !',
  'Le cout est trop élevé': 'Le cout est trop élevé',
  'Le montant global de la commande est trop élevé ':
    'Le montant global de la commande est trop élevé ',
  jours: 'jours',
  'Générer une facture': 'Générer une facture',
  'Montant rédacteur': 'Montant rédacteur',
  'Montant estimé par Typix': 'Montant estimé par Typix',
  'Voir la modification': 'Voir la modification',

  'Mes Factures': 'Mes Factures',
  'En attente de validation': 'En attente de validation',
  'Factures à envoyer': 'Factures à envoyer',
  'les valeurs négatives ne sont pas autorisées':
    'Les valeurs négatives ne sont pas autorisées.',
  'Texte Seulement': 'Texte seulement',
  'Traduction Seulement': 'Traduction seulement',
  'La relance peut être faite 20 jours après la soumissions de la facture':
    'La relance peut être faite 20 jours après la soumissions de la facture',
  Instructions: 'Instructions',
  'Votre avis pour la commande': 'Votre avis pour la commande',
  'Votre évaluation est précieuse pour nous ! Merci !':
    'Votre évaluation est précieuse pour nous ! Merci !',
  "Une erreur s'est produite lors de la soumission de votre évaluation. Veuillez réessayer ultérieurement!":
    "Une erreur s'est produite lors de la soumission de votre évaluation. Veuillez réessayer ultérieurement!",
  Evaluer: 'Evaluer',
  Commentaire: 'Commentaire',
  'Votre commantaire': 'Votre commantaire',
  Note: 'Note',
  ' Votre retour est précieux pour nous et nous aide à améliorer nos services.':
    ' Votre retour est précieux pour nous et nous aide à améliorer nos services.',
  'Verifier la facture': 'Verifier la facture',
  'Notes rédacteur': 'Notes rédacteur',
  'Avis client': 'Avis client',
  'Pas de commantaire': 'Pas de commantaire',
  "Qu'avez vous pensé de votre commande?":
    "Qu'avez vous pensé de votre commande?",
  'Evaluation de la commande': 'Evaluation de la commande',
  'Rédiger un commentaire': 'Rédiger un commentaire',
  'Contribuez à nous permettre de vous proposer les services de rédaction de la plus haute qualité en partageant vos commentaires.':
    'Contribuez à nous permettre de vous proposer les services de rédaction de la plus haute qualité en partageant vos commentaires.',
  'Que pouvons-nous améliorer?': 'Que pouvons-nous améliorer?',
  Continuer: 'Continuer',
  'Le dernier texte a été validé avec succès':
    'Le dernier texte a été validé avec succès',
  'Rechercher par rédacteur, commandes, et montant':
    'Rechercher par rédacteur, commandes, et montant',
  obligatoire: 'obligatoire',
  'Note admin': 'Note admin',
  'Note client': 'Note client',
  Partie: 'Partie',
  'Vous venez de valider tous les contenus rédigés par ':
    'Vous venez de valider tous les contenus rédigés par ',
  "Qu'en avez vous pensé": "Qu'en avez vous pensé",
  'La facture a été générée avec succès !':
    'La facture a été générée avec succès !',
  'La taille du titre et/ou la méta-description est trop courte':
    'La taille du titre et/ou la méta-description est trop courte',

  'afin de pouvoir livrer le texte': ' afin de pouvoir livrer le texte',
  'Demande de révision globale': 'Demande de révision globale',
  'Obligatoire en cas d’optimisation ou traduction':
    'Obligatoire en cas d’optimisation ou traduction',
  'Mots clés Bourrées': 'Mots clé à haute densité',
  'Votre commentaire...': 'Votre commentaire...',
  'Ex : "Contenus de très bonne qualité':
    'Ex : "Contenus de très bonne qualité',
  "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?":
    "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?",
  'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing':
    'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing',
  'Maillage Interne': 'Maillage Interne',
  'Modifier le texte': 'Modifier le texte',
  Ajouter: 'Ajouter',
  'Demande examen': 'Demande examen',
  'Recherche de mots clés à haute densité':
    'Recherche de mots clés à haute densité',
  'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ':
    'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ',
  'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.':
    'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).':
    'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).',
  'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.':
    'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.',
  'Votre demande a été bien envoyée !': 'Votre demande a été bien envoyée !',
  "Il y a eu un problème lors de l'envoi de votre demande":
    "Il y a eu un problème lors de l'envoi de votre demande",
  'Veuillez attendez pour le  keyword Stuffing analyse':
    'Veuillez attendez pour le keyword Stuffing analyse.',
  'Inserez le maillage interne': 'Inserez le maillage interne',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.':
    'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.',
  'Mots clés à haute densité dans le texte actuel':
    'Mots clés à haute densité dans le texte actuel',
  'Mots clés à haute densité dans le texte initial':
    'Mots clés à haute densité dans le texte initial',
  saving: 'enregistrement',
  saved: 'sauvegardé',
  'Texte en cours de rédaction !': 'Texte en cours de rédaction! ',
  'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.':
    'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.',
  "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande.":
    "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande.",
  'Voulez-vous confirmer l’URL actuelle ou la modifier ?':
    "Voulez-vous confirmer l'URL actuelle ou la modifier ?",
  'Veuillez fournir une URL valide': 'Veuillez fournir une URL valide',
  'Merci de bien vouloir indiquer ci-dessous l’url de la page où a été intégré le contenu:':
    "Merci de bien vouloir indiquer ci-dessous l'url de la page où a été intégré le contenu:",
  'Contenu en ligne': 'Contenu en ligne',
  'Télécharger le document de révision': 'Télécharger le document de révision',
  'Date d’envoi': 'Date d’envoi',
  Information: 'Information',
  Enregistrer: 'Enregistrer',
  'Textes en ligne': 'Textes en ligne',
  'Livrer les textes': 'Livrer les textes',
  Indisponible: 'Indisponible',
  Disponible: 'Disponible',
  'Les textes ont été livrés avec succès.':
    'Les textes ont été livrés avec succès.',
  'Le texte a été livré avec succès.': 'Le texte a été livré avec succès.',
  'Les textes ont été validés avec succès.':
    'Les textes ont été validés avec succès.',
  'Le texte a été validé avec succès.': 'Le texte a été validé avec succès.',
  'Voulez-vous vraiment valider les Textes de la commande':
    'Voulez-vous vraiment valider les Textes de la commande',
  'Voulez-vous vraiment livrer les Textes de la commande':
    'Voulez-vous vraiment livrer les Textes de la commande',
  'Fiche produit longue': 'Fiche produit longue',
  'Fiche produit courte': 'Fiche produit courte',
  'Un texte est trop long et ne peut pas être exporté sur Excel':
    'Un texte est trop long et ne peut pas être exporté sur Excel.',
  'Mettre un texte en brouillon en livré':
    'Mettre un texte en brouillon en livré',
  'Remettre un texte validé à livré': 'Remettre un texte validé à livré',
  'Invalider le texte': 'Retirer la validation',
  'Je souhaiterai modifier mon mot de passe : comment faire ?':
    'Je souhaiterai modifier mon mot de passe : comment faire ?',
  'Comment mettre un contenu en bon pour livraison ?':
    'Comment mettre un contenu en bon pour livraison ?',
  'Je ne peux pas mettre mon contenu en « brouillon »':
    'Je ne peux pas mettre mon contenu en « brouillon »',
  'Typix n’a pas enregistré mes dernières modifications':
    'Typix n’a pas enregistré mes dernières modifications',
  'Comment rédiger une création de contenu ?':
    'Comment rédiger une création de contenu ?',
  'Comment rédiger une optimisation de contenu ?':
    'Comment rédiger une optimisation de contenu ?',
  'Je ne vois plus ma commande – que faire ?':
    'Je ne vois plus ma commande – que faire ?',
  'Comment générer une facture ?': 'Comment générer une facture ?',
  'Comment générer une facture ?': 'Comment générer une facture ?',
  'Tuto process Typix global': 'Tuto process Typix global',
  'Tuto mise en ligne des contenus': 'Tuto mise en ligne des contenus',
  'Modification mot de passe': 'Modification mot de passe',
  'Le test ne me convient pas': 'Le test ne me convient pas',
  'Comment valider un contenu dans Typix ?':
    'Comment valider un contenu dans Typix ?',
  'Tuto Facturation': 'Tuto Facturation',
  'Le statut du contenu a bien été modifié':
    'Le statut du contenu a bien été modifié',
};
