import { entities } from './Constants';

export function removeWWW(url) {
  let index = url.indexOf('www.');
  return index > -1 ? url.slice(index + 'www.'.length) : url;
}

export function removeWhitespace(string) {
  return string.replace(/^\s+|\s+$/g, '');
}

export function lower(string) {
  return string.toLowerCase();
}

export function replaceTextInBold(text, array) {
  let content = text;

  array.map((keyword) => {
    // Escape special characters in the keyword for regex
    const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Handle spaces in the keyword for proper matching
    const keywordWithSpace = escapedKeyword.replace(/ /g, '(?:\\s|&nbsp;)');

    // Create a regex to match the keyword in the text, including those at the beginning of tags
    const regex = new RegExp(
      `(?:^|\\b|[^A-Za-zÀ-ÖØ-öø-ÿß])(${keywordWithSpace})(?![^<>]*>)`,
      'gi'
    );

    // Replace the keyword with the bold version
    content = content.replace(regex, (match, p1) =>
      match.replace(p1, `<strong>${p1}</strong>`)
    );
  });

  // Remove nested strong tags if any
  while (
    content.includes('<strong><strong>') &&
    content.includes('</strong></strong>')
  ) {
    content = content.replaceAll('<strong><strong>', '<strong>');
    content = content.replaceAll('</strong></strong>', '</strong>');
  }

  return content;
}

// export function replaceTextInBold(text, array) {
//   let content = text;
//   console.log(content);

//   array.map((x) => {
//     content = content.replace(
//       new RegExp(
//         `(?<![A-Za-zÀ-ÖØ-öø-ÿß<>])(${x})(?![A-Za-zÀ-ÖØ-öø-ÿß=<>])(?![^<>]*>)`,
//         'g'
//       ),
//       '<strong>' + x + '</strong>'
//     );
//   });

//   while (
//     content.includes('<strong><strong>') &&
//     content.includes('</strong></strong>')
//   ) {
//     content = content.replaceAll('<strong><strong>', '<strong>');
//     content = content.replaceAll('</strong></strong>', '</strong>');
//   }
//   return content;
// }

export function specialCharsToEntities(inputString) {
  return inputString.replace(/[&<>"'éâàêèëîïôœûùüçß]/g, function (match) {
    return entities[match];
  });
}

export function stripHTML(html) {
  var doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

export function entitiesToSpecialChars(inputString) {
  var entities = {
    '&amp;': '&',
    '&quot;': '"',
    '&#39;': "'",
    '&eacute;': 'é',
    '&acirc;': 'â',
    '&agrave;': 'à',
    '&ecirc;': 'ê',
    '&egrave;': 'è',
    '&euml;': 'ë',
    '&icirc;': 'î',
    '&iuml;': 'ï',
    '&ocirc;': 'ô',
    '&oelig;': 'œ',
    '&ucirc;': 'û',
    '&ugrave;': 'ù',
    '&uuml;': 'ü',
    '&ccedil;': 'ç',
    '&Ccedil;': 'Ç',
    '&szlig;': 'ß',
    '&ouml;': 'ö',
  };

  return inputString.replace(/&[^;]+;/g, function (match) {
    return entities[match] || match;
  });
}

export function allEntitiesToSpecialChars(inputString) {
  var entities = {
    '&amp;': '&',
    '&quot;': '"',
    '&#39;': "'",
    '&eacute;': 'é',
    '&acirc;': 'â',
    '&agrave;': 'à',
    '&ecirc;': 'ê',
    '&egrave;': 'è',
    '&euml;': 'ë',
    '&icirc;': 'î',
    '&iuml;': 'ï',
    '&ocirc;': 'ô',
    '&oelig;': 'œ',
    '&ucirc;': 'û',
    '&ugrave;': 'ù',
    '&uuml;': 'ü',
    '&ccedil;': 'ç',
    '&Eacute;': 'É',
    '&Acirc;': ' Â ',
    '&Agrave;': ' À',
    '&Ecirc;': 'Ê ',
    '&Egrave;': 'È',
    '&Euml;': 'Ë',
    '&Icirc;': 'Î',
    '&Iuml;': 'Ï',
    '&Ocirc;': 'Ô',
    '&Oelig;': 'Œ',
    '&Ucirc;': 'Û',
    '&Ugrave;': 'Ù',
    '&Uuml;': 'Ü ',
    '&Ccedil;': 'Ç',
    '&szlig;': 'ß',
    '&ouml;': 'ö',
  };

  return inputString.replace(/&[^;]+;/g, function (match) {
    return entities[match] || match;
  });
}

export function extractStringBefore(inputString, delimiter) {
  // Find the position of the delimiter
  var position = inputString.indexOf(delimiter);

  if (position !== -1) {
    // Extract the part of the string before the delimiter
    return inputString.substring(0, position);
  } else {
    // Return the original string if the delimiter is not found
    return inputString;
  }
}
export function replaceAmpersands(inputString) {
  // Use a regular expression to replace all occurrences of '&' with '&amp;'
  return inputString.replace(/&/g, '&amp;');
}
