import React, { useCallback, useEffect, useState } from 'react';
import axiosPrivate from 'config/axiosPrivate';

import { useDispatch } from 'react-redux';
import { addUser, updateUser } from 'store/Admin/UserManagement';

import { CustomChipsAutocomplete } from 'components/shared/DropDown/CustomChipsAutocomplete';
import { CustomAutocompleteMultipleChoice } from 'components/shared/DropDown/CustomAutocompleteMultipleChoice';

import { PWD_REGEX, EMAIL_REGEX, PHONENUMBER_REGEX } from 'utils/Regex';
import { ROLES } from 'utils/Constants';
import { lower } from 'utils/StringUtils';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { Modal } from 'reactstrap';

import { classes } from 'components/shared/modal/modalClasses';
import { disabledInput } from 'components/shared/disabledClasses';

import { MAIN_LANGUAGES } from 'utils/Constants';
import { useTranslation } from 'react-i18next';

const API = {
  createWriter: '/user/',
  updateWriter: '/user/',
  getAvailableThematics: '/thematic/all',
  getAvailableLanguages: '/language/all',
};

export const WriterModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [mail, setMail] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');

  const [limit, setLimit] = useState(0);

  const [address, setAddress] = useState('');
  const [companyName, setCompanyName] = useState('');

  const [availableThematicsList, setAvailableThematicsList] = useState([]);
  const [availableLanguagesList, setAvailableLanguagesList] = useState([]);

  const [mainWriterLanguages, setMainWriterLanguages] = useState([]);
  const [minorWriterLanguages, setMinorWriterLanguages] = useState([]);
  const [writerThematics, setWriterThematics] = useState([]);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const [disabledForm, setDisabledForm] = useState(true);

  useEffect(() => {
    axiosPrivate
      .get(API.getAvailableLanguages)
      .then((res) => setAvailableLanguagesList(res.data));

    axiosPrivate
      .get(API.getAvailableThematics)
      .then((res) => setAvailableThematicsList(res.data));

    if (props && props.user) {
      setMail(props.user.mail);
      setName(props.user.name);
      setPhoneNumber(props.user.phoneNumber);
      setCompanyName(props.user.companyName);
      setAddress(props.user.address);
      setLimit(props.user.limit);

      setWriterThematics(props.user.thematics);
      setMainWriterLanguages(
        props.user.languages.filter((x) => MAIN_LANGUAGES.includes(x.language))
      );
      setMinorWriterLanguages(
        props.user.languages.filter((x) => !MAIN_LANGUAGES.includes(x.language))
      );
    }
  }, [props]);

  const checkPasswordValid = useCallback(() => {
    return !password || PWD_REGEX.test(password);
  }, [password]);

  const checkValidForm = useCallback(() => {
    if (
      !mail ||
      !phoneNumber ||
      !name ||
      !companyName ||
      !address ||
      writerThematics.length === 0 ||
      (mainWriterLanguages.length === 0 && minorWriterLanguages.length === 0)
    ) {
      setDisabledForm(true);
      return;
    }

    if (mail.length > 0 && !mail.match(EMAIL_REGEX)) {
      setDisabledForm(true);
      return;
    }

    return setDisabledForm(!checkPasswordValid());
  }, [
    address,
    checkPasswordValid,
    companyName,
    mail,
    mainWriterLanguages,
    minorWriterLanguages,
    name,
    phoneNumber,
    writerThematics,
  ]);

  useEffect(() => {
    checkValidForm();
  }, [
    mail,
    name,
    password,
    phoneNumber,
    companyName,
    address,
    writerThematics,
    mainWriterLanguages,
    minorWriterLanguages,
    checkValidForm,
  ]);

  const callbackFromThematicMultiSelect = (newList) => {
    setWriterThematics(newList);
  };

  const callbackFromLanguagesMultiSelect = (newList) => {
    setMinorWriterLanguages(newList);
  };

  const findLanguageIndex = (language) => {
    return mainWriterLanguages.findIndex((element) =>
      lower(element.language).includes(lower(language))
    );
  };

  const checkLanguage = (language) => {
    let index = findLanguageIndex(language);
    let array = [...mainWriterLanguages];
    if (index > -1) {
      array.splice(index, 1);
    } else {
      let item = availableLanguagesList.find((element) =>
        lower(element.language).includes(lower(language))
      );
      array.push(item);
    }

    setMainWriterLanguages(array);
  };

  const updateWriter = () => {
    let body = {
      password: !password ? null : password,
      mail,
      name,
      phoneNumber,
      companyName,
      address,
      limit,
      languages: mainWriterLanguages.concat(minorWriterLanguages),
      thematics: writerThematics,
      role: ROLES.WRITER,
    };

    axiosPrivate
      .put(API.updateWriter, body, { params: { mail: props.user.mail } })
      .then((res) => {
        dispatch(updateUser({ ...res.data }));
        setIsSuccess(true);
        setErrorSuccessMsg(
          `${t('Le rédacteur')} ${props.user.mail} ${t(
            'a été mis à jour avec succès'
          )} !`
        );

        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 409:
            setErrorSuccessMsg(
              `${t("Un utilisateur existe déjà avec l'email")} ${mail}`
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  const createWriter = () => {
    let body = {
      mail,
      name,
      phoneNumber,
      companyName,
      address,
      limit,
      languages: mainWriterLanguages.concat(minorWriterLanguages),
      thematics: writerThematics,
      role: ROLES.WRITER,
    };

    axiosPrivate
      .post(API.createWriter, body)
      .then((res) => {
        dispatch(addUser({ ...res.data, role: ROLES.WRITER }));
        setIsSuccess(true);
        setErrorSuccessMsg(
          `${t('Le rédacteur')} ${mail} ${t('a été créé avec succès')}`
        );

        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 409:
            setErrorSuccessMsg(
              `${t("Un utilisateur existe déjà avec l'email")} ${mail}`
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  if (props.user)
    return (
      <div>
        <Modal style={classes.largeModalCustom} isOpen={props.open}>
          <div style={classes.modalHeader} className="modal-header">
            <h5 style={classes.title} className="modal-title">
              {t('Modifier le rédacteur')}
            </h5>
            <button
              type="button"
              onClick={props.handleClose}
              style={classes.btnClose}
              className="btn-close"
            />
          </div>
          <div style={classes.modalPad} className="modal-body">
            <div className="row">
              <div className="col-12 col-md-5">
                <div className="row row-mb-lg">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Prénom/Nom')}</div>
                    <div>
                      <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        className="form-control form-ct"
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb-lg">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('E - mail')}</div>
                    <div>
                      <input
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                        type="text"
                        className="form-control form-ct"
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb-lg">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Adresse')}</div>
                    <div>
                      <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="form-control form-ct"
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb-lg">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Société')}</div>
                    <div>
                      <input
                        type="text"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        className="form-control form-ct"
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb-lg">
                  <div className="col-12 col-md-6">
                    <div className="label-title">{t('Téléphone')}</div>
                    <div>
                      <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => {
                          if (
                            e.target.value === '' ||
                            PHONENUMBER_REGEX.test(e.target.value)
                          ) {
                            setPhoneNumber(e.target.value);
                          }
                        }}
                        className="form-control form-ct"
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb-lg">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Mot de passe')}*</div>
                    <div className="input-group input-password">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        onClick={() => setShowPassword(!showPassword)}
                        className="input-group-text"
                      >
                        {showPassword ? (
                          <img src="/Images/icon-eye-black.png" alt="eye" />
                        ) : (
                          <img src="/Images/icon-eye-gray.png" alt="eye" />
                        )}
                      </span>
                    </div>
                    <div className="text-more mt-2">
                      *
                      {t(
                        'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.'
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-7">
                <div className="row row-mb-lg">
                  <div className="col-12 col-md-8">
                    <div className="label-title">ID</div>

                    <input
                      style={(disabledInput, { width: 250 })}
                      type="text"
                      disabled
                      value={props.user.id}
                      className="form-control form-ct"
                    />
                  </div>
                </div>
                <div className="row row-mb-lg">
                  <div className="col-12 col-md-8">
                    <div className="label-title">{t('Analyse / Limite')}</div>
                    <div
                      className="form-control form-gr form-ct"
                      style={{ width: 250 }}
                    >
                      <span
                        style={{
                          backgroundColor: 'rgb(233, 236, 239)',
                          padding: '0 10px',
                          borderRadius: '15px',
                        }}
                      >
                        {props.user.analysis > limit ? 0 : props.user.analysis}
                      </span>{' '}
                      /
                      <input
                        type="number"
                        min="0"
                        value={limit}
                        style={{ width: '75px' }}
                        className="white-input"
                        onChange={(e) => setLimit(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb-lg">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Thématiques')}</div>
                    <CustomChipsAutocomplete
                      callbackFromMultiSelect={callbackFromThematicMultiSelect}
                      value={writerThematics}
                      disabled={!availableThematicsList}
                      list={availableThematicsList}
                      setValue={(option) => setWriterThematics(option)}
                      getOptionLabel={(option) => {
                        return t(option.thematic);
                      }}
                    />
                  </div>
                </div>
                <div className="row row-mb-lg">
                  <div className="col-12 col-md-12">
                    <div className="label-title mb-2">{t('Langues :')}</div>
                    <div className="box-wrapper-cols">
                      {MAIN_LANGUAGES.slice(0, 3).map((language, key) => (
                        <div key={key} className="cols">
                          <div className="box-checkbox box-list-check">
                            <input
                              checked={findLanguageIndex(language) > -1}
                              onChange={() => checkLanguage(language)}
                              className="form-check-input"
                              type="checkbox"
                            />
                            <div> {t(language)}</div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="box-wrapper-cols mt-4">
                      {MAIN_LANGUAGES.slice(3).map((language, key) => (
                        <div key={key} className="cols">
                          <div className="box-checkbox box-list-check">
                            <input
                              checked={findLanguageIndex(language) > -1}
                              onChange={() => checkLanguage(language)}
                              className="form-check-input"
                              type="checkbox"
                            />
                            <div> {t(language)}</div>
                          </div>
                        </div>
                      ))}
                      <div className="cols">
                        <CustomAutocompleteMultipleChoice
                          callbackFromMultiSelect={
                            callbackFromLanguagesMultiSelect
                          }
                          value={minorWriterLanguages}
                          disabled={!availableLanguagesList}
                          list={availableLanguagesList.filter(
                            (x) =>
                              !MAIN_LANGUAGES.map((lan) => lower(lan)).includes(
                                lower(x.language)
                              )
                          )}
                          setValue={(option) => setMinorWriterLanguages(option)}
                          getOptionLabel={(option) => {
                            return t(option.language);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-button-bt">
              <div>
                {disabledForm ? (
                  <button className="bt-submit-disabled-y float-end">
                    {t('Sauvegarder')}
                  </button>
                ) : (
                  <button
                    className="bt-submit-y float-end"
                    onClick={updateWriter}
                  >
                    {t('Sauvegarder')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>
        <ErrorSuccesModal
          open={errorSuccess}
          message={errorSuccessMsg}
          handleClose={() => {
            if (isSuccess === true) props.handleClose();
            setErrorSuccess(false);
          }}
          success={isSuccess}
          toggle={() => setErrorSuccess(!errorSuccess)}
        />
      </div>
    );

  return (
    <div>
      <Modal style={classes.largeModalCustom} isOpen={props.open}>
        <div style={classes.modalHeader} className="modal-header">
          <h5 style={classes.title} className="modal-title">
            {t('Ajouter un rédacteur')}
          </h5>
          <button
            type="button"
            onClick={props.handleClose}
            style={classes.btnClose}
            className="btn-close"
          />
        </div>
        <div style={classes.modalPad} className="modal-body">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="row row-mb-lg">
                <div className="col-12 col-md-12">
                  <div className="label-title">{t('Prénom/Nom')}</div>
                  <div>
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      className="form-control form-ct"
                    />
                  </div>
                </div>
              </div>
              <div className="row row-mb-lg">
                <div className="col-12 col-md-12">
                  <div className="label-title">{t('E - mail')}</div>
                  <div>
                    <input
                      value={mail}
                      onChange={(e) => setMail(e.target.value)}
                      type="text"
                      className="form-control form-ct"
                    />
                  </div>
                </div>
              </div>
              <div className="row row-mb-lg">
                <div className="col-12 col-md-12">
                  <div className="label-title">{t('Adresse')}</div>
                  <div>
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="form-control form-ct"
                    />
                  </div>
                </div>
              </div>
              <div className="row row-mb-lg">
                <div className="col-12 col-md-12">
                  <div className="label-title">{t('Société')}</div>
                  <div>
                    <input
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="form-control form-ct"
                    />
                  </div>
                </div>
              </div>
              <div className="row row-mb-lg">
                <div className="col-12 col-md-6">
                  <div className="label-title">{t('Téléphone')}</div>
                  <div>
                    <input
                      type="text"
                      value={phoneNumber}
                      onChange={(e) => {
                        if (
                          e.target.value === '' ||
                          PHONENUMBER_REGEX.test(e.target.value)
                        ) {
                          setPhoneNumber(e.target.value);
                        }
                      }}
                      className="form-control form-ct"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-7">
              <div className="row row-mb-lg">
                <div className="col-12 col-md-8">
                  <div className="label-title">{t('Analyse/Limite')}</div>
                  <div
                    className="form-control form-gr form-ct"
                    style={{ width: 250 }}
                  >
                    <span
                      style={{
                        backgroundColor: 'rgb(233, 236, 239)',
                        padding: '0 10px',
                        borderRadius: '15px',
                      }}
                    >
                      {' '}
                      0{' '}
                    </span>{' '}
                    /
                    <input
                      type="number"
                      min="0"
                      value={limit}
                      style={{ width: '75px' }}
                      className="white-input"
                      onChange={(e) => setLimit(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row row-mb-lg">
                <div className="col-12 col-md-12">
                  <div className="label-title">{t('Thématiques')}</div>
                  <CustomChipsAutocomplete
                    callbackFromMultiSelect={callbackFromThematicMultiSelect}
                    value={writerThematics}
                    disabled={!availableThematicsList}
                    list={availableThematicsList}
                    setValue={(option) => setWriterThematics(option)}
                    getOptionLabel={(option) => {
                      return t(option.thematic);
                    }}
                  />
                </div>
              </div>
              <div className="row row-mb-lg">
                <div className="col-12 col-md-12">
                  <div className="label-title mb-2">{t('Langues')}:</div>
                  <div className="box-wrapper-cols">
                    {MAIN_LANGUAGES.slice(0, 3).map((language, key) => (
                      <div key={key} className="cols">
                        <div className="box-checkbox box-list-check">
                          <input
                            checked={findLanguageIndex(language) > -1}
                            onChange={() => checkLanguage(language)}
                            className="form-check-input"
                            type="checkbox"
                          />
                          <div> {t(language)}</div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="box-wrapper-cols mt-4">
                    {MAIN_LANGUAGES.slice(3).map((language, key) => (
                      <div key={key} className="cols">
                        <div className="box-checkbox box-list-check">
                          <input
                            checked={findLanguageIndex(language) > -1}
                            onChange={() => checkLanguage(language)}
                            className="form-check-input"
                            type="checkbox"
                          />
                          <div> {t(language)}</div>
                        </div>
                      </div>
                    ))}
                    <div className="cols">
                      <CustomAutocompleteMultipleChoice
                        callbackFromMultiSelect={
                          callbackFromLanguagesMultiSelect
                        }
                        value={minorWriterLanguages}
                        disabled={!availableLanguagesList}
                        list={availableLanguagesList.filter(
                          (x) =>
                            !MAIN_LANGUAGES.map((lan) => lower(lan)).includes(
                              lower(x.language)
                            )
                        )}
                        setValue={(option) => setMinorWriterLanguages(option)}
                        getOptionLabel={(option) => {
                          return t(option.language);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-button-bt">
            <div>
              {disabledForm ? (
                <button className="bt-submit-disabled-y float-end">
                  {t('Sauvegarder')}
                </button>
              ) : (
                <button
                  className="bt-submit-y float-end"
                  onClick={createWriter}
                >
                  {t('Sauvegarder')}
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => {
          if (isSuccess === true) props.handleClose();
          setErrorSuccess(false);
        }}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
};

export default WriterModal;
